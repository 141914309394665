function clearActive(anchors: HTMLAnchorElement[]) {
  anchors.forEach((anchor) => anchor.removeAttribute('data-active'));
}

function setup(node: HTMLElement) {
  const swiperUuid = node.getAttribute('data-phc');
  if (!swiperUuid) return;

  if (!window.swipers) return;

  const swiperInstance = window.swipers.find(
    (i) => i.uuid === swiperUuid
  )?.swiper;
  if (!swiperInstance) return;

  const availableStartPoints: HTMLAnchorElement[] = Array.from(
    node.querySelectorAll<HTMLAnchorElement>('[data-phc-start]')
  );

  availableStartPoints.forEach((anchor) => {
    anchor.addEventListener('click', (e) => {
      // no clickity please
      e.preventDefault();
      clearActive(availableStartPoints);
      anchor.setAttribute('data-active', 'true');
      const slideTo = Number(anchor.getAttribute('data-phc-start') ?? '0');
      swiperInstance.slideTo(slideTo);
    });
  });

  // Back button return to previous search
  const previousUrl = document.referrer;
  const urlPieces = previousUrl.split(/[/?]/);
  if (urlPieces[3] === 'properties') {
    const backToSearch: HTMLAnchorElement = document.getElementById(
      'backToSearch'
    ) as HTMLAnchorElement;
    backToSearch.href = previousUrl;
  }

  /**
   * Listen to swiper changes to activate the proper anchor tag
   * and ensuring it works in both directions
   */
  swiperInstance.on('slideChange', (swiper) => {
    const activeAnchorStart = node.querySelector(
      `[data-phc-start="${swiper.activeIndex}"]`
    );
    const activeAnchorEnd = node.querySelector(
      `[data-phc-end="${swiper.activeIndex}"]`
    );
    const carouselWrapper: HTMLElement = document.querySelector(
      '[data-last-photo-index]'
    );
    const { lastPhotoIndex } = carouselWrapper.dataset;
    const lastPhotoIndexNum = parseInt(lastPhotoIndex, 10);
    const showAllPhotosButton: HTMLElement = document.querySelector(
      '#propertyDetail__imageGridButton'
    );
    if (swiper.activeIndex > lastPhotoIndexNum) {
      showAllPhotosButton.style.display = 'none';
    } else {
      showAllPhotosButton.style.removeProperty('display');
    }
    if (!activeAnchorStart && !activeAnchorEnd) return;
    clearActive(availableStartPoints);
    (activeAnchorStart || activeAnchorEnd).setAttribute('data-active', 'true');
  });
}

export default function init(): void {
  const propertyDetailHero: HTMLElement[] = Array.from(
    document.querySelectorAll<HTMLElement>('[data-phc]')
  );

  propertyDetailHero.forEach((module) => setup(module));
}
