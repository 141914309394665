/**
 * Imports
 */

import { getLoginStatus } from '../utils/LoginStatus';

/**
 * NavAccountLinks Class
 *
 * If for any reason we ever want external control, we can set a variable
 * on the window of the return from this class in app.ts then access it like
 * `window.NavAccountLinks.handleAccountLinks();` from any file that comes after
 */
class NavAccountLinks {
  /**
   * Array of our AccountLinks
   */
  accountLinks: HTMLAnchorElement[] = null;

  constructor(accountLinks: NodeListOf<HTMLAnchorElement>) {
    this.accountLinks = Array.from(accountLinks);
  }

  /**
   * Fetches to see if a user is currenly logged in, adjusting accountLinks accordingly.
   *
   * @return    {undefined}          returns nothing, undefined
   */
  handleAccountLinks(): void {
    // const userIsLoggedIn = await fetchUserLoginStatus();
    const userIsLoggedIn = getLoginStatus();
    const showTheseLinksWhenLoggedIn = this.accountLinks.filter(
      (link) => link.getAttribute('data-show') === 'logged-in'
    );
    const showTheseLinksWhenLoggedOut = this.accountLinks.filter(
      (link) => link.getAttribute('data-show') === 'logged-out'
    );

    // If user is logged in
    if (userIsLoggedIn) {
      showTheseLinksWhenLoggedIn.forEach((linkToShow) => {
        linkToShow.style.display = 'inline-flex';
      });
      showTheseLinksWhenLoggedOut.forEach((linkToHide) => {
        linkToHide.style.display = 'none';
      });
      return undefined;
    }

    // If user is logged out
    showTheseLinksWhenLoggedOut.forEach((linkToShow) => {
      linkToShow.style.display = 'inline-flex';
    });
    showTheseLinksWhenLoggedIn.forEach((linkToHide) => {
      linkToHide.style.display = 'none';
    });

    return undefined;
  }

  init() {
    this.handleAccountLinks();
  }
}

/**
 * Initialization
 *
 * @return    {NavAccountLinks}          returns instance of the class
 */
export default function initModule(): NavAccountLinks {
  const accountLinks: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(
    '[data-account-link]'
  );

  if (!accountLinks) {
    return null;
  }

  const accountLinkClass = new NavAccountLinks(accountLinks);
  accountLinkClass.init();

  return accountLinkClass;
}
