import initAccount from './modules/Account';
import initHeader from './modules/Header';
import { initImageFader } from './modules/ImageFader';
import initInputs from './modules/Input';
import initListingUI from './modules/InstantSearch';
import { initModals, initSprigModal } from './modal';
import initPropertyDetailHero from './modules/PropertyDetailHero';
import initPropertySearchMaps from './modules/PropertySearchMap';
import initSwiperCarousels from './modules/SwiperCarousels';
import initSwitches from './modules/SwitchTemplate';
import initToggle from './modules/Toggle';

import {
  initCharts,
  initHeroSearch,
  initShareCurrentUrl,
  initVideoControllers,
  initZipSearch
} from './modules';
import { initLoginStatusComponents } from './utils/Account';
import initLeafLeatAttribution from './utils/LeafletAttribution';
import initClickDimensions from './utils/ClickDimensions';

const App = (): void => {
  initSwiperCarousels();
  initImageFader();
  initAccount();
  initHeader();
  initHeroSearch();
  initListingUI();
  initModals();
  initPropertyDetailHero();
  initPropertySearchMaps();
  initSwitches();
  initToggle();
  initInputs();
  initVideoControllers();
  initShareCurrentUrl();
  initSprigModal();
  initCharts();
  initZipSearch();
  initLoginStatusComponents();
  initLeafLeatAttribution();
  initClickDimensions();
};

document.addEventListener('DOMContentLoaded', () => {
  App();
});
