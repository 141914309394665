/**
 * Imports
 */
// import { debounce, throttle } from 'lodash';

/**
 * For some reason lodash is causing problems and not giving a single error to understand why/how.
 */
const debounce = (callback: Function, time: number = 250) => {
  let timer: any;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(this, args);
    }, time);
  };
};

/**
 * Header Class
 */
class Header {
  body: HTMLBodyElement | any;

  header: HTMLElement = null;

  logo: HTMLElement = null;

  mobileTrigger: HTMLButtonElement;

  navLinks: HTMLAnchorElement[];

  navLinksInSubnav: HTMLAnchorElement[];

  navLinksWithChildren: HTMLAnchorElement[];

  accountLinks: HTMLAnchorElement[];

  headerBreakpoint: number = 1280; // 1280 = gorko "lg" breakpoint (80em)

  constructor(headerEl: HTMLElement) {
    this.body = document.body;
    this.header = headerEl;
    this.logo = this.header.querySelector('[data-header-logo]');
    this.mobileTrigger = this.header.querySelector('[data-mobile-trigger]');
    this.navLinks = Array.from(
      this.header.querySelectorAll('[data-header-link]')
    );
    this.navLinksInSubnav = Array.from(
      this.header.querySelectorAll('[data-header-link="subnav"]')
    );
    this.navLinksWithChildren = this.navLinks.filter(
      (link) => link.getAttribute('data-has-children') === 'true'
    );
    this.accountLinks = this.navLinks.filter((link) =>
      link.hasAttribute('data-account-link')
    );
  }

  /**
   * Handle the mobile trigger (hamburger) being clicked to open/close nav
   *
   * @return    {undefined}          returns nothing, undefined
   */
  handleMobileTriggerClick() {
    this.body.classList.toggle('b-body--locked');
    this.header.classList.toggle('b-header--mobileNavActive');
    this.handleResponsiveTabIndexing();
  }

  /**
   * Handle a click on a nav item with children.
   * If desktop: ignore.
   * If mobile: preventDefault, open subnav and adjust tabIndexing
   *
   * @return    {undefined}          returns nothing, undefined
   */
  handleLinkWithChildrenClick(e: any): undefined {
    const isMobileMenu = window.innerWidth < this.headerBreakpoint;
    const targetLinkParent = e.target.parentNode;
    const childLinks = targetLinkParent.querySelectorAll(
      '[data-header-link="subnav"]'
    );

    if (isMobileMenu) {
      e.preventDefault();
      targetLinkParent.classList.toggle('active');

      if (targetLinkParent.classList.contains('active')) {
        childLinks.forEach((link: any) => {
          link.tabIndex = '0'; // eslint-disable-line no-param-reassign
        });
      } else {
        childLinks.forEach((link: any) => {
          link.tabIndex = '-1'; // eslint-disable-line no-param-reassign
        });
      }
    } else {
      return undefined;
    }

    return undefined;
  }

  /**
   * Handles Responsive TabIndexing by disabling the links when mobile and not active
   *
   * @return    {undefined}                    returns nothing, undefined
   */
  handleResponsiveTabIndexing(): undefined {
    const isMobileMenu = window.innerWidth < this.headerBreakpoint;
    const isMobileMenuActive = this.header.classList.contains(
      'b-header--mobileNavActive'
    );

    if (isMobileMenu && !isMobileMenuActive) {
      this.navLinks.forEach((link) => {
        link.tabIndex = -1; // eslint-disable-line no-param-reassign
      });

      return undefined;
    }

    this.navLinks.forEach((link) => {
      // Don't adjust subnav links on mobile, we handle those a little differently
      if (!this.navLinksInSubnav.includes(link) || !isMobileMenu) {
        link.tabIndex = 0; // eslint-disable-line no-param-reassign
      }
    });

    return undefined;
  }

  /**
   * Resets things such as active states whenever a user changes from small to large viewport or vice-versa
   *
   * @return    {undefined}          returns nothing, undefined
   */
  handleResponsiveUX(): undefined {
    const isDesktopHeader = window.innerWidth > this.headerBreakpoint;

    if (isDesktopHeader) {
      this.body.classList.remove('b-body--locked');
      this.header.classList.remove('b-header--mobileNavActive');
      this.navLinks.forEach((link) => {
        const linkParent = link.parentElement;
        if (linkParent.classList.contains('active')) {
          linkParent.classList.remove('active');
        }
      });
    }

    return undefined;
  }

  /**
   * Method for attaching our event listeners
   */
  attachListeners() {
    // Basic hamburger menu click
    this.mobileTrigger.addEventListener('click', () =>
      this.handleMobileTriggerClick()
    );

    // Handle links with subnavs
    this.navLinksWithChildren.forEach((link) => {
      link.addEventListener('click', (e) =>
        this.handleLinkWithChildrenClick(e)
      );
    });

    // Window Events
    window.addEventListener(
      'resize',
      debounce(() => this.handleResponsiveTabIndexing())
    );
    window.addEventListener(
      'resize',
      debounce(() => this.handleResponsiveUX())
    );
  }

  init() {
    // Remove NoJS Data Attr to signal that JS is enabled
    this.header.removeAttribute('data-js-disabled');

    // Attach listeners and any other methods that need to run on init
    this.attachListeners();
    this.handleResponsiveTabIndexing();
  }
}

/**
 * Initialization
 *
 * @return    {undefined}          [returns nothing, initializes header]
 */
export default function initHeader(): Header {
  let header: any = document.getElementById('header');

  if (!header) {
    return null;
  }

  header = new Header(header);
  header.init();

  return header;
}
