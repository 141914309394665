import { connectPagination } from 'instantsearch.js/es/connectors';

type TArrayFromNumber = (length: number, offset?: number) => number[];
const arrayFromNumber: TArrayFromNumber = (length, offset = 0) => {
  return Array.from({ length }, (_, index) => index + offset);
};

const getDisplayPagesArray = (
  pages: number[],
  numOfHits: number,
  activePageIndex: number
): number[] => {
  /**
   * Creating a Custom pagination page numbers array
   *
   * Step 1: Get the UI for the hits per page
   */
  const hitsPerPageUI = document.querySelector(
    '.ais-HitsPerPage-select'
  ) as HTMLSelectElement;
  if (hitsPerPageUI) {
    const pagesShown = 5;
    /**
     * Step 2: Get the active value
     * This function is rerun when the hitsper page is updated, so no need
     * for an event listener or watcher.
     */
    const { selectedIndex } = hitsPerPageUI;
    const hitsPerPageValue = parseInt(
      hitsPerPageUI.options[selectedIndex].value,
      10
    );
    // Step 3: utilize nbHits to figue out how many pages should exist
    const numOfPages = Math.ceil(numOfHits / hitsPerPageValue);
    const numOfPagesIndex = numOfPages - 1;
    // Step 4: Check if number of pages is lower than what should be shown (5). If it is return just the number of pages as an array.
    if (numOfPages < 5) {
      return arrayFromNumber(numOfPages);
    }
    /**
     * Step 5: Control the output array, ensuring to check how close the active page is to
     * the bounds of the number of pages array
     */
    // If near low bound
    if (activePageIndex < 3) {
      /**
       * If the page is #3 (index 2) or below, send back the first 5 items.
       */
      return arrayFromNumber(pagesShown);
    }
    // If near high bound
    if (activePageIndex > numOfPagesIndex - 3) {
      /**
       * If within 3 return the last 5 items as an array
       * Ex: 15 pages, for page 13, 14, 15. Or indexes 12,13,14
       * We just want the last 5 items.
       */
      return arrayFromNumber(pagesShown, numOfPages - pagesShown);
    }

    /**
     * If not near bounds simply return an array of numbers with a length
     * equal to the desired num of pages shown, with an offset equal to the active
     * page index - the padding (a static value of 2).
     */
    return arrayFromNumber(pagesShown, activePageIndex - 2);
  }

  /**
   * Add regular pages as a fall back
   */
  return pages;
};

/**
 * Interface for RenderOptions properties
 * @see https://www.algolia.com/doc/api-reference/widgets/pagination/js/#create-a-render-function
 */
interface IRenderOptions {
  pages: number[];
  currentRefinement: number;
  nbHits: number;
  nbPages: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  canRefine: boolean;
  refine: Function;
  createURL: Function;
  widgetParams: any;
}

// Create the render function
const renderPagination = (renderOptions: IRenderOptions) => {
  const {
    pages,
    currentRefinement,
    isFirstPage,
    isLastPage,
    refine,
    createURL,
    nbHits
  } = renderOptions;
  /**
   * Ensuring 5 pages are always displayed for the pagination component
   *
   * [@seandepottey]:
   * I dug through the instant meilisearch library to see exactly how pages array was set.
   * Essentially they're coming from the results, and I'm not sure we'll have luck
   * with either an existing setting in instantsearch / instantmeilisearch that will properly
   * control the number of buttons. A possible alternative is upgrading instantmeilisearch,
   * but that will would also mean we likely have to update the meilisearch version,
   * which seems costly.
   *
   * The render options can be overridden here. Or rather we can use some of the others provided to
   * recreate some of the same functionality that the connector function is doing but for our needs.
   *
   * Vars included in `renderOptions`
   * nbHits (number): The total number of hits, this has been uppped to 2.5k and as long as that number isn't exceeded this should work. It could likely account for going over as well, but not sure it's worth the investment of time right now.
   * currentRefinement (number:index): This is the index of the active page
   *
   * pages (number[]): This is an array of numbers that would control which pages would display. But does not behaive how we want it to. Could also reach into UI, since I believe it may be a select and get active value directly out of there.
   */
  const pageNumbersArray = getDisplayPagesArray(
    pages,
    nbHits,
    currentRefinement
  );

  const container = document.querySelector('#pagination');

  container.innerHTML = /* html */ `
    <div class="b-listingPagination__divider"></div>

    <ul class="b-listingPagination__main">
      ${
        isFirstPage
          ? /* html */ `
      <li class="ais-Pagination-item ais-Pagination-item--disabled">
        <span class="ais-Pagination-link">
          <svg class='b-icon u-box-flex'>
            <use href='/svg/sprite.svg#arrow-left'></use>
          </svg>
        </span>
      </li>
      `
          : /* html */ `
      <li class="ais-Pagination-item">
        <a href="${createURL(currentRefinement - 1)}" data-value="${
          currentRefinement - 1
        }" class="ais-Pagination-link">
          <svg class='b-icon u-box-flex'>
            <use href='/svg/sprite.svg#arrow-left'></use>
          </svg>
        </a>
      </li>
      `
      }

      ${pageNumbersArray
        .map(
          (page) => /* html */ `
            <li class="ais-Pagination-item ${
              currentRefinement === page ? 'ais-Pagination-item--selected' : ''
            }">
              <a
                class="ais-Pagination-link"
                href="${createURL(page)}"
                data-value="${page}"
              >
                ${page + 1}
              </a>
            </li>
          `
        )
        .join('')}

      ${
        isLastPage
          ? /* html */ `
      <li class="ais-Pagination-item ais-Pagination-item--disabled">
        <span class="ais-Pagination-link">
          <svg class='b-icon u-box-flex'>
            <use href='/svg/sprite.svg#arrow-right'></use>
          </svg>
        </span>
      </li>
      `
          : /* html */ `
      <li class="ais-Pagination-item">
        <a href="${createURL(currentRefinement + 1)}" data-value="${
          currentRefinement + 1
        }" class="ais-Pagination-link">
          <svg class='b-icon u-box-flex'>
            <use href='/svg/sprite.svg#arrow-right'></use>
          </svg>
        </a>
      </li>
      `
      }
    </ul>

    <div class="b-listingPagination__divider"></div>

    <ul class="b-listingPagination__arrows">
      ${
        isFirstPage
          ? /* html */ `
      <li class="ais-Pagination-item ais-Pagination-item--prevPage ais-Pagination-item--disabled">
        <span class="ais-Pagination-link">
          <svg class='b-icon u-box-flex'>
            <use href='/svg/sprite.svg#arrow-left'></use>
          </svg>
        </span>
      </li>
      `
          : /* html */ `
      <li class="ais-Pagination-item ais-Pagination-item--prevPage">
        <a href="${createURL(currentRefinement - 1)}" data-value="${
          currentRefinement - 1
        }" class="ais-Pagination-link">
          <svg class='b-icon u-box-flex'>
            <use href='/svg/sprite.svg#arrow-left'></use>
          </svg>
        </a>
      </li>
      `
      }

      ${
        isLastPage
          ? /* html */ `
      <li class="ais-Pagination-item ais-Pagination-item--nextPage ais-Pagination-item--disabled">
        <span class="ais-Pagination-link">
          <svg class='b-icon u-box-flex'>
            <use href='/svg/sprite.svg#arrow-right'></use>
          </svg>
        </span>
      </li>
      `
          : /* html */ `
      <li class="ais-Pagination-item ais-Pagination-item--nextPage">
        <a href="${createURL(currentRefinement + 1)}" data-value="${
          currentRefinement + 1
        }" class="ais-Pagination-link">
          <svg class='b-icon u-box-flex'>
            <use href='/svg/sprite.svg#arrow-right'></use>
          </svg>
        </a>
      </li>
      `
      }
    </ul>
  `;

  [...container.querySelectorAll('a')].forEach((element) => {
    element.addEventListener('click', (event) => {
      event.preventDefault();
      const target = event.currentTarget as HTMLAnchorElement;
      if (target) refine(target.dataset.value);
    });
  });
};

// Create the custom widget
export const CustomPagination = connectPagination(renderPagination);

export default CustomPagination;
