import { spriteIcon } from './SpriteIcon';

type TButtonTag = 'a' | 'button';

interface IButtonTemplate {
  className?: string;
  content: string;
  href?: string;
  iconName?: string;
  tagType?: TButtonTag;
  textSize?: number;
}

export const buttonTemplate = ({
  className,
  content,
  href,
  iconName,
  tagType = 'a',
  textSize = 0
}: IButtonTemplate) => {
  const icon = iconName ? spriteIcon(iconName) : '';
  const hrefAttr = href ? `href='${href}'` : '';
  const classes = className || '';

  return /* html */ `
    <${tagType} class='b-button b-anchorComplex c-withIcon u-text-${textSize} ${classes}' ${hrefAttr}>
      <span>${content}</span>
      ${icon}
    </${tagType}>
  `;
};

export default buttonTemplate;
