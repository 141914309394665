import { connectRefinementList } from 'instantsearch.js/es/connectors';

/**
 * ListingType Refinement List (Custom UI)
 *
 * @see https://www.algolia.com/doc/api-reference/widgets/refinement-list/js/#create-a-render-function
 */
const renderRefinementList = (renderOptions: any, isFirstRender: any) => {
  const { refine, createURL, widgetParams } = renderOptions;

  if (isFirstRender) {
    const ul = document.createElement('ul');
    ul.setAttribute('role', 'list');
    ul.setAttribute('class', 'ais-RefinementList | u-text-0');

    widgetParams.container.appendChild(ul);
  }

  // Get currentRefinements from existing elements
  const currentRefinements: string[] = Array.from(
    document.querySelectorAll('.ais-CurrentRefinements-categoryLabel')
  ).map((item: any) => item.textContent);

  const allListingsEnabled = currentRefinements.includes('All Listing Types');
  const allAuctionsEnabled = currentRefinements.includes('All Auctions');

  // Render list items markup, always same order
  widgetParams.container.querySelector('ul').innerHTML = `
    <li>
      <a
        href="${createURL('All Listing Types')}"
        data-value="${'All Listing Types'}"
        class="${allListingsEnabled ? 'active' : ''}"
      >
        <input
          type="checkbox"
          class="ais-RefinementList-checkbox"
          ${allListingsEnabled ? 'checked' : ''}
        > All Listing Types
      </a>
    </li>

    <li>
      <a
        href="${createURL('For sale')}"
        data-value="${'For sale'}"
        class="${allListingsEnabled ? 'parent-enabled' : ''}"
      >
        <input
          type="checkbox"
          class="ais-RefinementList-checkbox"
          ${
            currentRefinements.includes('For sale') || allListingsEnabled
              ? 'checked'
              : ''
          }
        > For Sale
      </a>
    </li>

    <li class="hasSublist active">
      <a
        href="${createURL('All Auctions')}"
        data-value="${'All Auctions'}"
        class="${allListingsEnabled ? 'parent-enabled' : ''}"
      >
        <input
          type="checkbox"
          class="ais-RefinementList-checkbox"
          ${allAuctionsEnabled || allListingsEnabled ? 'checked' : ''}
        > All Auctions
      </a>

      <button class="sublistTrigger"><svg class='b-icon'><use href='/svg/sprite.svg#chevron-up'></use></svg></button>

      <ul role="list">
        <li>
          <a
            href="${createURL('In-Person Auction')}"
            data-value="${'In-Person Auction'}"
            class="${
              allListingsEnabled || allAuctionsEnabled ? 'parent-enabled' : ''
            }"
          >
            <input
              type="checkbox"
              class="ais-RefinementList-checkbox"
              ${
                currentRefinements.includes('In-Person Auction') ||
                allAuctionsEnabled ||
                allListingsEnabled
                  ? 'checked'
                  : ''
              }
            > In-Person Auction
          </a>
        </li>

        <li>
          <a
            href="${createURL('Online Auction')}"
            data-value="${'Online Auction'}"
            class="${
              allListingsEnabled || allAuctionsEnabled ? 'parent-enabled' : ''
            }"
          >
            <input
              type="checkbox"
              class="ais-RefinementList-checkbox"
              ${
                currentRefinements.includes('Online Auction') ||
                allAuctionsEnabled ||
                allListingsEnabled
                  ? 'checked'
                  : ''
              }
            > Online Auction
          </a>
        </li>

        <li>
          <a
            href="${createURL('Simulcast Auction')}"
            data-value="${'Simulcast Auction'}"
            class="${
              allListingsEnabled || allAuctionsEnabled ? 'parent-enabled' : ''
            }"
          >
            <input
              type="checkbox"
              class="ais-RefinementList-checkbox"
              ${
                currentRefinements.includes('Simulcast Auction') ||
                allAuctionsEnabled ||
                allListingsEnabled
                  ? 'checked'
                  : ''
              }
            > Simulcast Auction
          </a>
        </li>

        <li>
          <a
            href="${createURL('For Sale by Bid')}"
            data-value="${'For Sale by Bid'}"
            class="${
              allListingsEnabled || allAuctionsEnabled ? 'parent-enabled' : ''
            }"
          >
            <input
              type="checkbox"
              class="ais-RefinementList-checkbox"
              ${
                currentRefinements.includes('For Sale by Bid') ||
                allAuctionsEnabled ||
                allListingsEnabled
                  ? 'checked'
                  : ''
              }
            > For Sale by Bid
          </a>
        </li>
      </ul>
    </li>
  `;

  [...widgetParams.container.querySelectorAll('a')].forEach((element) => {
    element.addEventListener('click', (event: any) => {
      event.preventDefault();
      refine(event.currentTarget.dataset.value);
    });
  });

  [
    ...widgetParams.container.querySelectorAll(
      '.hasSublist button.sublistTrigger'
    )
  ].forEach((trigger: any) => {
    trigger.addEventListener('click', (e: any) => {
      e.preventDefault();
      const parentListItem = e.currentTarget.parentNode;
      parentListItem.classList.toggle('active');
    });
  });
};

const listingTypeRefinementList = connectRefinementList(renderRefinementList);

export default listingTypeRefinementList;
