/**
 * Imports
 */
import { MeiliSearch } from 'meilisearch';
import { numFormatter } from '../utils/Index';
import { getZipCodeCoordinates } from '../utils/Maps';

/**
 * Types
 */
export interface LatLongPos {
  lat: number | string;
  lng: number | string;
}

/**
 * Parent class to all leaflet classes
 */
class LeafletMap {
  /**
   * Attribution element bar for Leaflet + ESRI
   */
  attributionEl: HTMLElement = null;

  /**
   * Default ZIP from CMS
   */
  defaultZip: string = null; // Charlotte default

  /**
   * Default zoom for map
   */
  defaultZoom: number = 13;

  /**
   * Whether or not the user has geolocation allowed
   */
  geolocationAllowed: boolean = false;

  /**
   * Leaf instance
   */
  Leaf: any = window.L;

  /**
   * HTML element that is the container for loading animation(s)
   */
  loadingContainer: HTMLElement = null;

  /**
   * Leaflet map instance
   */
  map: any = null;

  /**
   * Map Container element for leaflet
   */
  mapContainer: HTMLElement = null;

  /**
   * Array of markers currently attached to the map
   */
  markers: any[] = null;

  /**
   * Meilisearch Client
   */
  meiliClient: MeiliSearch = new MeiliSearch({
    host: process.env.MEILI_FRONTEND_HOST,
    apiKey: process.env.MEILI_API_KEY
  });

  /**
   * Says "You" if geolocated, "{Query|ZIP Code}" if using a normal string query
   */
  relativeLocationTextEl: HTMLElement = null;

  /**
   * The number of results in: {X} Properties Near You
   */
  resultsLengthTextEl: HTMLElement = null;

  /**
   * Search input element
   */
  searchInput: HTMLInputElement = null;

  /**
   * Takes search results and adds markers (into a cluster) to the map for each
   *
   * @param     {any}                searchResults    Array of search results to add to the map
   *
   * @return    {undefined}                     returns nothing
   */
  addResultMarkersToMap(searchResults: any): undefined {
    if (!searchResults.length) {
      return undefined;
    }

    const markerArray: any[] = [];
    const clusterGroup: any = this.Leaf.markerClusterGroup();

    searchResults.forEach((result: any) => {
      // Custom Marker
      const resultIcon = this.Leaf.divIcon({
        className: 'custom-marker',
        html: `
           <a href="${result.url}">
             <i class="${result.propertyStatus.toLowerCase()}"></i>
             <span>${result.price ? numFormatter(result.price) : '$'}</span>
             <i class="arrow">
               <svg viewBox="0 0 15 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                 <path d="M0.263672 6.9548H11.9433V9.31266H0.263672V6.9548Z" />
                 <path d="M7.00059 16.0348L5.39039 14.3833L11.4842 8.13434L5.39039 1.88538L7.00059 0.233887L14.7024 8.13437L7.00059 16.0348Z" />
               </svg>
             </i>
           </a>
         `
      });

      const resultMarker = this.Leaf.marker(
        [result._geo.lat, result._geo.lng],
        {
          icon: resultIcon,
          keyboard: true,
          title: result.title
        }
      );

      markerArray.push(resultMarker);
      clusterGroup.addLayer(resultMarker);
    });

    if (markerArray.length > 1) {
      const markerBoundsGroup = this.Leaf.featureGroup(markerArray);
      this.map.fitBounds(markerBoundsGroup.getBounds().pad(0.175)); // Pad based on zoom delta
    } else {
      this.map.setView(
        [markerArray[0].getLatLng().lat, markerArray[0].getLatLng().lng],
        this.defaultZoom
      );
    }

    this.map.addLayer(clusterGroup);
    this.markers = markerArray;
    return undefined;
  }

  /**
   * Get search results from meilisearch.
   *
   * #TODO: Support for additional filters from a listing/search page
   *
   * @param     {LatLongPos|string}        query    Query is either an object containing lat/lng or a string that should contain a zip code
   *
   * @param     {any[]}        filters    Object of filters to be used with the meilisearch query
   *
   * @return    {Promise<any>}             Returns promise of search request
   */
  async getSearchResults(
    query: LatLongPos | string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    filters: any[] = null
  ): Promise<any> {
    let searchResults = null;
    // 200 mile radius (200 * 1609.34) : 1 mile === 1609.34 meters
    const limitChange =
      filters?.find((prop: string) => prop === 'limit') ?? false;
    const radiusInMeters = limitChange ? 3218680000 : 321868;

    // Via lat/lng
    if (typeof query === 'object') {
      searchResults = await this.meiliClient
        .index('fncRealEstate_properties')
        .search('', {
          filter: "propertyStatus != 'Sold'",
          limit: limitChange ? 1000 : 20 // change default limit
        });

      return searchResults;
    }

    // Via zip code
    if (typeof query === 'string' && query.match(/^\d+$/g)) {
      const zipCoords = await getZipCodeCoordinates(query).then(
        (details) => details
      );

      if (zipCoords === null) {
        return undefined;
      }

      searchResults = await this.meiliClient
        .index('fncRealEstate_properties')
        .search('', {
          filter: [
            `_geoRadius(
            ${zipCoords.lat},
             ${zipCoords.long},
             ${radiusInMeters}
           )`,
            "propertyStatus != 'Sold'"
          ]
        });

      return searchResults;
    }

    // Via traditional query string
    if (typeof query === 'string') {
      searchResults = await this.meiliClient
        .index('fncRealEstate_properties')
        .search(query, {
          filter: "propertyStatus != 'Sold'"
        });

      return searchResults;
    }

    return undefined;
  }

  /**
   * Handles adjusting the marker positioning to be more realistic
   * - Since we're using custom markers the initial placement of them is a bit jacked up
   *
   * @return    {undefined}            Returns nothing, undefined
   */
  handleMarkerAdjustments(): undefined {
    const mapMarkers = Array.from(
      this.mapContainer.querySelectorAll('.custom-marker')
    );
    mapMarkers.forEach((marker: any) => {
      const existingTransform = window.getComputedStyle(marker).transform;
      marker.style.transform = `translateX(-50%) ${existingTransform}`; // eslint-disable-line
    });

    return undefined;
  }

  /**
   * Handles a change of the search input
   *
   * @param     {string}    newQuery    New string to search for
   *
   * @return    {undefined}              returns nothing
   */
  async handleSearchInputChange(newQuery: string): Promise<any> {
    if (!newQuery) {
      return undefined;
    }

    if (this.markers !== null) {
      this.markers.forEach((marker) => {
        marker.remove();
      });
    }
    this.markers = null;

    const newResults = await this.getSearchResults(newQuery).then(
      (results) => results
    );

    if (!newResults) {
      this.updateMap([], newQuery.toString(), '0', newQuery.toString());
      return undefined;
    }

    this.updateMap(
      newResults.hits,
      newQuery.toString(),
      newResults.hits.length,
      newQuery.toString()
    );

    return undefined;
  }

  /**
   * Loads the leaflet map
   *
   * @return    {undefined}          returns nothing
   */
  loadMap() {
    // Charlotte default view, for now
    this.map = this.Leaf.map(this.mapContainer, {
      maxZoom: 25,
      zoomSnap: 0.75,
      zoomDelta: 0.75,
      zoomControl: false,
      scrollWheelZoom: false,
      attributionControl: false
    }).setView([35.2271, -80.8431], this.defaultZoom); // TODO: client-approved default center?

    // Define the Satellite (Imagery) layer
    var satelliteLayer = window.L.esri.Vector.vectorBasemapLayer(
      'ArcGIS:Imagery',
      {
        apikey: process.env.ESRI_API_KEY
      }
    );

    // Define the Navigation layer
    var navigationLayer = window.L.esri.Vector.vectorBasemapLayer(
      'ArcGIS:Navigation',
      {
        apikey: process.env.ESRI_API_KEY
      }
    );

    // Add the default layer (Satellite) to the map
    satelliteLayer.addTo(this.map);

    // Add a layer control to switch between layers
    var baseMaps = {
      Satellite: satelliteLayer,
      Navigation: navigationLayer
    };

    // Add controls to map
    window.L.control.layers(baseMaps).addTo(this.map);
    window.L.control.zoom({ position: 'bottomright' }).addTo(this.map);

    // Any event listeners for the map
    this.map.addEventListener(
      'zoomend',
      this.handleMarkerAdjustments.bind(this)
    );
  }

  /**
   * If a user has enabled location services,
   * get their lat/lng and query results based on that
   */
  async searchViaGeolocation(): Promise<any> {
    this.loadingContainer.classList.add('loading');

    if (navigator.geolocation && window.location.pathname !== '/') {
      navigator.geolocation.getCurrentPosition(
        /**
         * Success callback
         */
        async (position) => {
          this.geolocationAllowed = true;
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const coords = {
            lat,
            lng
          };

          const geoResults: any = await this.getSearchResults(coords).then(
            (results) => results
          );

          this.updateMap(geoResults.hits, '', geoResults.hits.length, null);
        },
        /**
         * Error callback
         *
         * - Immediate response after a geolocation error should be searching via defaultZip
         */
        async (error) => {
          // Explicitly adjust state value and log something
          if (error.PERMISSION_DENIED && window.location.href.includes('dev')) {
            this.geolocationAllowed = false;
            /* eslint-disable no-console */
            console.log(
              'Permission for geolocation and location services denied'
            );
            /* eslint-enable no-console */
          }
          const defaultCoords = { lat: 37.09024, lng: -95.712891 };
          const defaultResults = await this.getSearchResults(defaultCoords, [
            'limit'
          ]).then((results) => results);

          this.updateMap(
            defaultResults.hits,
            '',
            defaultResults.hits.length,
            ''
          );
        }
      );
    } else {
      const defaultCoords = { lat: 37.09024, lng: -95.712891 };
      const defaultResults = await this.getSearchResults(defaultCoords, [
        'limit'
      ]).then((results) => results);

      this.updateMap(defaultResults.hits, '', defaultResults.hits.length, '');
    }

    return undefined;
  }

  /**
   * Updates the map and loading state after a request finishes
   *
   * @param {array} results Array of results from search
   *
   * @param {string} searchInputValue String value to be passed into the ZIP input
   *
   * @param {string|null} resultsLengthText null if we don't want to adjust, string if so
   *
   * @param {string|null} relativeLocationText null if we don't want to adjust, string if so
   *
   * @return {undefined} returns nothing
   */
  updateMap(
    results: any[],
    searchInputValue: string = '',
    resultsLengthText: string | null = null,
    relativeLocationText: string | null = null
  ) {
    // Conditional things
    if (results.length > 0) {
      this.addResultMarkersToMap(results);
      this.handleMarkerAdjustments();
    }

    if (resultsLengthText !== null && this.resultsLengthTextEl) {
      this.resultsLengthTextEl.textContent = resultsLengthText;
    }

    if (relativeLocationText !== null && this.relativeLocationTextEl) {
      this.relativeLocationTextEl.textContent = relativeLocationText;
    }

    // Do this every time
    if (this.searchInput) this.searchInput.value = searchInputValue;
    this.loadingContainer.classList.remove('loading');
  }
}

export default LeafletMap;
