export const getSiteHandle = (): string => {
  const bodyElement = document.querySelector(
    'body[data-handle]'
  ) as HTMLElement;
  if (bodyElement) {
    return bodyElement.dataset.handle;
  }

  return '';
};
