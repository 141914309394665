import { connectGeoSearch } from 'instantsearch.js/es/connectors';
import { numFormatter } from '../../utils/Index';
import { LatLongPos } from '../LeafletMap';

interface IRenderGeoSearchOptions {
  items: any;
  widgetParams: any;
}

interface IMapIcon {
  _geoloc: LatLongPos;
  friendlyName?: string;
  price?: number;
  totalAcres?: number;
  propertyStatus?: string;
  url: string;
  title?: string;
  isAuction: boolean;
}

let map: any = null;
let markers: any = [];

const propertyResultIcon = ({
  totalAcres,
  price,
  propertyStatus,
  isAuction,
  url
}: IMapIcon) => {
  const acresText = /* html */ `${totalAcres} &#177;&nbsp; ac.`;
  const displayContent = isAuction ? acresText : numFormatter(price);
  return /* html */ `
  <a href="${url}" class='b-anchorComplex'>
    <i class="${propertyStatus.toLowerCase()}"></i>
    <span>${displayContent}</span>
    <i class="arrow">
      <svg viewBox="0 0 15 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.263672 6.9548H11.9433V9.31266H0.263672V6.9548Z" />
        <path d="M7.00059 16.0348L5.39039 14.3833L11.4842 8.13434L5.39039 1.88538L7.00059 0.233887L14.7024 8.13437L7.00059 16.0348Z" />
      </svg>
    </i>
  </a>
  `;
};

const agentResultIcon = ({ friendlyName, url }: IMapIcon) => {
  return /* html */ `
  <a href='${url}' class='b-agentMapIndicator b-anchorComplex | c-withIcon'>
    <span>${friendlyName}</span>
  </a>
  `;

  // Arrow icon
  // <svg class='b-icon' viewBox="0 0 15 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  //   <path d="M0.263672 6.9548H11.9433V9.31266H0.263672V6.9548Z" />
  //   <path d="M7.00059 16.0348L5.39039 14.3833L11.4842 8.13434L5.39039 1.88538L7.00059 0.233887L14.7024 8.13437L7.00059 16.0348Z" />
  // </svg>
};

const mapResultIcon = (iconType: string, data: IMapIcon) => {
  switch (iconType) {
    case 'property':
      return {
        className: 'custom-marker',
        html: propertyResultIcon(data)
      };
    case 'agent':
      return {
        className: 'custom-marker',
        html: agentResultIcon(data)
      };
    default:
      return null;
  }
};

const renderGeoSearch = (
  renderOptions: IRenderGeoSearchOptions,
  isFirstRender: boolean
) => {
  const { items, widgetParams } = renderOptions;
  const { container } = widgetParams;

  if (isFirstRender) {
    // const button = document.createElement('button');
    // button.textContent = 'Clear the map refinement';

    container.style.height = '700px';
    map = window.L.map(container, {
      maxZoom: 17,
      scrollWheelZoom: false,
      zoomControl: false,
      attributionControl: false
    }).setView([35.2271, -80.8431], 15);

    window.currentMap = map;

    // Define the Satellite (Imagery) layer
    var satelliteLayer = window.L.esri.Vector.vectorBasemapLayer(
      'ArcGIS:Imagery',
      {
        apikey: process.env.ESRI_API_KEY
      }
    );

    // Define the Navigation layer
    var navigationLayer = window.L.esri.Vector.vectorBasemapLayer(
      'ArcGIS:Navigation',
      {
        apikey: process.env.ESRI_API_KEY
      }
    );

    // Add the default layer (Satellite) to the map
    satelliteLayer.addTo(map);

    // Add a layer control to switch between layers
    var baseMaps = {
      Satellite: satelliteLayer,
      Navigation: navigationLayer
    };

    // Add controls to map
    window.L.control.layers(baseMaps).addTo(map);
    window.L.control.zoom({ position: 'bottomright' }).addTo(map);

    // container.appendChild(button);
  }

  // Remove existing markers
  map.eachLayer((layer: any) => {
    if (layer && layer instanceof window.L.MarkerClusterGroup) {
      map.removeLayer(layer);
    }
  });

  // Cluster group for clustering
  const clusterGroup: any = window.L.markerClusterGroup();

  // const propertyStatus

  markers = items.map((data: IMapIcon) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _geoloc, title } = data;
    // Custom Marker
    const iconType = data.friendlyName ? 'agent' : 'property';
    const resultIcon = window.L.divIcon(mapResultIcon(iconType, data));

    const customMarker = window.L.marker([_geoloc.lat, _geoloc.lng], {
      icon: resultIcon,
      title
    });

    clusterGroup.addLayer(customMarker);

    return customMarker;
  });

  map.addLayer(clusterGroup);

  // const markerBoundsGroup = window.L.featureGroup(markers);

  if (markers.length) {
    map.fitBounds(window.L.featureGroup(markers).getBounds(), {
      padding: [5, 5]
    });
  }
};

const leafletGeoSearch = connectGeoSearch(renderGeoSearch);

export default leafletGeoSearch;
