import { connectSortBy } from 'instantsearch.js/es/connectors';

/**
 * Interface for RenderOptions properties
 * @see https://www.algolia.com/doc/api-reference/widgets/sort-by/js/#create-a-render-function
 */
interface IRenderOptions {
  options: {
    isDefault?: boolean;
    label: string;
    value: string;
  }[];
  currentRefinement: string;
  // canRefine: boolean; // See note starting on line 62
  refine: Function;
  widgetParams: any;
}

export const renderSortBy = (
  renderOptions: IRenderOptions,
  isFirstRender: boolean
) => {
  const {
    // canRefine, // See note starting on line 62
    currentRefinement,
    options,
    refine,
    widgetParams
  } = renderOptions;

  if (isFirstRender) {
    const select = document.createElement('select');

    select.addEventListener('change', (event) => {
      // Refine with the <select> value
      refine((event.target as HTMLSelectElement).value);
    });

    // Add cssClasses to respective elements
    if (widgetParams?.cssClasses) {
      select.setAttribute('class', widgetParams.cssClasses.select);
      select.querySelectorAll('option').forEach((option) => {
        option.setAttribute('class', widgetParams.cssClasses.option);
      });
      widgetParams.container.setAttribute(
        'class',
        widgetParams.cssClasses.root
      );
    }

    widgetParams.container.appendChild(select);

    // Let's also sort|refine the results by default, if there is one
    const defaultOption: any = options.filter((option) => option.isDefault)[0];
    if (defaultOption) {
      refine(defaultOption.value);
    }
  }

  const select = widgetParams.container.querySelector('select');
  select.setAttribute(
    'class',
    'u-text-base u-weight-bold u-t-color-neutrals-primary-500'
  );

  /**
   * Strange issue where canRefine isn't within the SortByRenderState
   * But it works on NWO so just ignoring this on FNC and never letting it be disabled
   */
  // select.disabled = !canRefine;

  select.innerHTML = `
    ${options
      .map(
        (option) => `
          <option
            value="${option.value}"
            data-tracking-value="${option.label}"
            ${option.value === currentRefinement ? 'selected' : ''}
          >
            ${option.label}
          </option>
        `
      )
      .join('')}
  `;
};

export const CustomSortBy = connectSortBy(renderSortBy);

export default CustomSortBy;
