export const styledPriceTemplate = (price: string) => {
  if (price === '0') {
    return '';
  }

  return /* html */ `
    <p class="u-weight-bold">
      $${price}
    </p>
  `;
};

export default styledPriceTemplate;
