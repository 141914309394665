import { buttonTemplate, spriteIcon, styledPriceTemplate } from '../templates';
import { propertyFaderTemplate } from './PropertyFader';

interface IPropertyListing {
  countyState: string;
  listingAgent: string;
  listingNumber: string;
  price: number;
  formattedPrice: string;
  pricePerAcre: number;
  formattedPricePerAcre: string;
  propertyPhotos: string[];
  propertyStatus: string;
  propertyTypes: string[];
  id: string;
  isAuction: boolean;
  isMultiTract: boolean;
  eyebrow: string;
  timeLeft: number;
  totalAcres: number;
  url: string;
  displayLocation: string;
  auctionType: string;
  actualSoldPrice: string;
  auctionDateStart: string;
  auctionDateEnd: string;
  soldDate: string;
}

// #region - Property Listing Pieces
const saleTypeTemplate = (saleType: string, className?: string) => {
  const classesExtension = className ? ` ${className}` : '';
  return /* html */ `<p class='b-saleTypeText u-tt-upper ${classesExtension}'>${saleType}</p>`;
};

export const listingTitle = (text: string, className?: string) => {
  const classesExtension = className ? ` ${className}` : '';
  return /* html */ `
    <h3 class='b-listingTitle | u-t-color-core-secondary u-text-2 u-weight-bold u-gap-top-0${classesExtension}'>${text}</h3>
  `;
};

export const acresTemplate = (acres: number) => {
  if (!acres) {
    return '';
  }

  return /* html */ `
  <p>
    ${acres}<span class='u-color-secondary-box-text'>&#177;&nbsp;</span> Acres
  </p>
  `;
};

const styledTractTemplate = (isMultiTract: boolean, className?: string) => {
  const classesExtension = className ? ` ${className}` : '';
  return /* html */ `
  <p class="b-styledTract | u-weight-bold | u-gap-right-200${classesExtension}">
    ${isMultiTract ? 'Multi-Tract' : 'Single Tract'}
  </p>
  `;
};

const acresTractTemplate = (isMultiTract: boolean, acres: number) => {
  return /* html */ `
  <p class="b-styledTract | u-gap-right-200">
    <span class="u-weight-bold u-gap-right-200">${
      isMultiTract ? 'Multi-Tract' : 'Single Tract'
    }</span>${acres}<span class='u-color-secondary-box-text'>&#177;&nbsp;</span> Acres
  </p>
  `;
};

const priceOrDateTemplate: (
  auctionType: string,
  isAuction: boolean,
  isPending: boolean,
  isSold: boolean,
  price: string,
  actualSoldPrice: string | null,
  auctionDateStart: string | null,
  auctionDateEnd: string | null
) => string = (
  auctionType,
  isAuction,
  isPending,
  isSold,
  price,
  actualSoldPrice = null,
  auctionDateStart = null,
  auctionDateEnd = null
) => {
  // Active auctions, return date instead of price
  if (isAuction && !isPending && !isSold) {
    const icon = spriteIcon(
      'calendar',
      'u-t-color-core-primary-500 u-gap-right-100 u-text-1'
    );

    switch (auctionType) {
      case 'In-Person Auction':
        return `${icon} <span>${auctionDateStart}</span>`;
      case 'Online Auction':
      case 'Simulcast Auction':
        return `${icon} <span>${auctionDateStart} - ${auctionDateEnd}</span>`;
      case 'For Sale by Bid':
        return `${icon} <span>Bids Due: ${auctionDateEnd}</span>`;
      default:
        return `${icon} <span>TBD</span>`;
    }
  }

  // Properties OR Auctions that are pending||sold, AND have an actualSoldPrice
  if (isAuction && (isPending || isSold) && actualSoldPrice) {
    return styledPriceTemplate(actualSoldPrice);
  }

  // Properties that are active should show listing price
  if (!isAuction && !isPending && !isSold) {
    return styledPriceTemplate(price);
  }

  // If the above conditionals aren't met, don't show anything.
  return '';
};

const perAcreTemplate = (formattedPricePerAcre: string) => {
  if (
    !formattedPricePerAcre ||
    formattedPricePerAcre === '0' ||
    formattedPricePerAcre === 'undefined'
  )
    return '';

  return /* html */ `
  <p class="b-styledPrice">
    or $${formattedPricePerAcre}/acre
  </p>
  `;
};

const propertyStatusTemplate = (propertyStatus: string) => {
  const propertyStatusPieces = propertyStatus.split(' ');
  const propertyStatusClassExtension = propertyStatusPieces.join('');

  return /* html */ `
  <p class='b-propertyStatus b-propertyStatus--${propertyStatusClassExtension} | eyebrow eyebrow--clear u-tt-upper u-t-color-neutrals-primary-500 u-text-0'>${propertyStatus}</p>
  `;
};

const propertyTypesTemplate = (types: string[]) => {
  if (!types || types.length === 0) {
    return '';
  }

  const propertyTypes = types
    .map((type: string) => {
      return type;
    })
    .join(', ');

  const propertyTypesDisplay = /* html */ `<p class='b-propertyListing__types | u-t-color-neutrals-primary-500'>${propertyTypes}</p>`;

  return propertyTypesDisplay;
};

const listingAgentTemplate = (agentName: string) => {
  return /* html */ `
  <div class='b-propertyListing__agents'>
    <h5 class='u-tt-upper'>listing agent(s)</h5>
    <p class='u-t-color-core-secondary'>${agentName}</p>
  </div>`;
};

export const checkPropertyPhotos = (propertyPhotos: string[]) => {
  return !propertyPhotos || propertyPhotos.length === 0
    ? ['/dist/img/re-profile-default.png']
    : propertyPhotos;
};

const locationTemplate = (location: string, className?: string) => {
  const classesExtension = className ? ` ${className}` : '';
  return location === '' || location === 'N/A'
    ? ''
    : /* html */ `<p class='${classesExtension}'>${location}</p>`;
};
// #endregion - Property Listing Pieces

const propertyListingHalfCard: (data: IPropertyListing) => string = ({
  listingNumber,
  propertyPhotos,
  formattedPrice,
  propertyStatus,
  id,
  isAuction,
  isMultiTract,
  eyebrow,
  totalAcres,
  url,
  displayLocation,
  actualSoldPrice,
  auctionType,
  auctionDateStart,
  auctionDateEnd
}) => {
  const photos = checkPropertyPhotos(propertyPhotos);
  const isSold = propertyStatus.toLowerCase() === 'sold';
  const isPending = propertyStatus.toLowerCase() === 'pending';

  return /* html */ `
  <a class='b-propertyListing b-anchorComplex | c-cluster | md:u-hoverTarget-shift u-propertyFaderHover' tabindex='0' href='${url}'>
    ${propertyFaderTemplate(id, photos)}
    <div class='b-propertyListing__content | md:u-hoverEffect-shift'>
      ${saleTypeTemplate(eyebrow)}
      ${listingTitle(listingNumber)}

      <div class='c-repel'>
        ${locationTemplate(displayLocation)}
        ${priceOrDateTemplate(
          auctionType,
          isAuction,
          isPending,
          isSold,
          formattedPrice,
          actualSoldPrice,
          auctionDateStart,
          auctionDateEnd
        )}
      </div>
      <div class='c-repel | u-flex-align-end'>
        <div class='u-box-flex'>
          ${styledTractTemplate(isMultiTract)}
          ${acresTemplate(totalAcres)}
        </div>
        <div class="u-box-flex u-flex-dir-column">
          ${
            isAuction && formattedPrice !== '0' && (isSold || isPending)
              ? `<div class="u-text-0">
                    <p class="u-weight-bold">
                        $${formattedPrice}
                    </p>
                </div>`
              : ``
          }
          ${propertyStatusTemplate(propertyStatus)}
        </div>
      </div>
    </div>
  </a>
  `;
};

const propertyListingFullCard: (data: IPropertyListing) => string = ({
  formattedPrice,
  listingAgent,
  listingNumber,
  propertyPhotos,
  pricePerAcre,
  propertyStatus,
  propertyTypes,
  id,
  isMultiTract,
  eyebrow,
  totalAcres,
  displayLocation,
  url,
  isAuction,
  auctionType,
  actualSoldPrice,
  auctionDateStart,
  auctionDateEnd
}) => {
  const photos = checkPropertyPhotos(propertyPhotos);
  const isSold = propertyStatus.toLowerCase() === 'sold';
  const isPending = propertyStatus.toLowerCase() === 'pending';
  const buttonTheme =
    document.body.getAttribute('data-handle') === 'huntingLeaseNetwork'
      ? 'secondary'
      : 'primary';

  return /* html */ `
  <div class='b-propertyListing b-propertyListing--full u-propertyFaderHover | c-cluster'>
    <a href="${url}">
      ${propertyFaderTemplate(id, photos)}
    </a>

    <div class='b-propertyListing__info'>
      ${saleTypeTemplate(eyebrow, 'b-propertyListing__info__type u-shiftHover')}
      <a href="${url}" class='b-propertyListing__priceOrDate | u-text-base u-weight-bold'>
        ${priceOrDateTemplate(
          auctionType,
          isAuction,
          isPending,
          isSold,
          formattedPrice,
          actualSoldPrice,
          auctionDateStart,
          auctionDateEnd
        )}
      </a>

      <div class='b-propertyListing__info__content'>
        <div class='u-text-00'>
          ${locationTemplate(displayLocation)}
        </div>
        <a href="${url}" class='u-text-00'>
          ${listingTitle(listingNumber)}
        </a>
      </div>

      <div class='b-propertyListing__info__content'>
        <div class='u-text-00'>
          ${acresTractTemplate(isMultiTract, totalAcres)}
        </div>
        <a href="${url}" class='u-text-00'>
          ${propertyStatusTemplate(propertyStatus)}
        </a>
      </div>
    </div>

    <div class='b-propertyListing__info b-propertyListing__info--desktop | c-flow | u-flow-space-200'>
      <div class='c-flow | u-flow-space-100'>
        ${saleTypeTemplate(
          eyebrow,
          'u-shiftHover b-propertyListing__info__type'
        )}
        <a href="${url}">${listingTitle(listingNumber)}</a>
        ${locationTemplate(displayLocation)}
      </div>

      <div class='u-box-flex'>
        ${styledTractTemplate(isMultiTract)}
        ${acresTemplate(totalAcres)}
      </div>

      ${propertyStatusTemplate(propertyStatus)}
    </div>

    <div class='b-propertyListing__additionalInfo | c-flow'>
      ${listingAgentTemplate(listingAgent)}
      ${propertyTypesTemplate(propertyTypes)}
    </div>

    <div class='b-propertyListing__action | c-flow u-flex-justify-around u-flex-align-start | sm:u-flex-align-end'>
      <div class='b-propertyListing__priceOrDate | u-ta-right u-weight-bold u-text-0 u-box-flex u-flex-align-center u-flex-justify-between'>
        ${priceOrDateTemplate(
          auctionType,
          isAuction,
          isPending,
          isSold,
          formattedPrice,
          actualSoldPrice,
          auctionDateStart,
          auctionDateEnd
        )}
        ${perAcreTemplate(`${pricePerAcre}`)}
      </div>

      ${buttonTemplate({
        className: `b-button b-anchorComplex b-button--${buttonTheme} b-button--with-icon | c-withIcon`,
        content: 'See Details',
        href: url,
        iconName: 'arrow-right'
      })}
    </div>
  </div>
  `;
};

const propertyListingNoResults = () => {
  return `
    <div class='b-propertyListing | c-cluster'>
      <h2>No Results Found</h2>
    </div>
  `;
};

const propertyTableRow: (
  data: IPropertyListing,
  hitPosition: number
) => string = (
  {
    countyState,
    listingAgent,
    propertyStatus,
    listingNumber,
    totalAcres,
    formattedPrice,
    soldDate,
    url
  },
  hitPosition
) => {
  const [county, state] = countyState.split(',');
  const hiddenData = JSON.stringify({
    soldDate,
    status: propertyStatus,
    listingId: listingNumber.split(' ').join('&nbsp;'),
    county: county.split(' ').join('&nbsp;'),
    url
  });

  const listingAgents = listingAgent.split(', ').join('<br>');

  return `
  <tr class="${
    hitPosition % 2 ? 'even' : 'odd'
  }" data-details=${hiddenData} data-colspan="5">
    <td class="c-box"><a href="${url}">${listingNumber}</a></td>
    <td class="c-box">${county}</td>
    <td class="c-box">${state}</td>
    <td class="c-box">${county}</td>
    <td class="c-box">${listingAgents}</td>
    <td class="c-box">${totalAcres}</td>
    <td class="c-box">$${formattedPrice}</td>
    <td class="c-box"><span class="b-propertyStatus b-propertyStatus--${propertyStatus}">${propertyStatus}</span></td>
    <td class="c-box">${soldDate}</td>
    <td>
        <button class="b-collapseButton">
          <svg class="b-icon b-collapseButton__caret"><use href="/svg/sprite.svg#caret-down"></use></svg>
        </button>
    </td>
  </tr>
  `;
};

// eslint-disable-next-line import/prefer-default-export
export {
  propertyListingHalfCard,
  propertyListingFullCard,
  propertyListingNoResults,
  propertyTableRow,
  IPropertyListing
};
