import {
  initFavoriteProperties,
  isPropertyAFavorite,
  openModal,
  savedPropertyAsFavorite
} from '../FavoriteProperties';
import {
  initImageFader,
  imageFaderTemplate,
  unmountImageFaders
} from '../ImageFader';
import { spriteIcon } from '../templates';

// This component has a twig version in the basic card
// It may be better named without alluding to properties
export const propertyFaderTemplate = (propertyId: string, images: string[]) => {
  if (!images || images.length === 0) return '';

  return /* html */ `
  <div class='b-propertyFader | c-frame c-withLayer | c-frame--aspect-1-1 | u-width-100'>
    <div class='c-frame c-wtih-layer'>
      ${imageFaderTemplate(images)}
      <div class='c-layer | u-box-flex u-flex-justify-end'>
        <div class='b-propertyFader__faderControls | c-box | u-box-flex u-flex-dir-column u-flex-justify-between'>
          <button class='b-favoritePropertyToggle b-star' data-property-id='${propertyId}' data-favorite-property-toggle>
            ${spriteIcon('star')}
          </button>
          <div class='b-pill | c-withIcon c-box'>
            ${spriteIcon('camera')}
            <p>${images.length}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  `;
};

// Runs after instantsearch UI is created it enables
// favorite a property button functionality and
// Set's the default state of the button
export const initPropertyFaderFavorites = () => {
  const propertyFaderFavButtons = document.querySelectorAll(
    '.b-favoritePropertyToggle'
  );

  if (!propertyFaderFavButtons.length) return;

  initFavoriteProperties();
  const propertyListings = document.querySelectorAll('.b-propertyListing');
  propertyListings.forEach((listing) => {
    const favButton = listing.querySelector(
      '[data-property-id]'
    ) as HTMLButtonElement;
    const { propertyId } = favButton.dataset;
    isPropertyAFavorite(propertyId).then((isFav) => {
      if (isFav) {
        favButton.classList.add('b-favoritePropertyToggle--active');
      }
    });
  });
};

export const unmountPropertyFaderFavorites = () => {
  const propertyFaderFavButtons = document.querySelectorAll(
    '.b-favoritePropertyToggle'
  );

  if (!propertyFaderFavButtons.length) return;

  // const favoriteFunction = USER.isLoggedIn
  const userIsLoggedIn =
    sessionStorage.getItem('userLoginStatus') === 'true' || false;
  const favoriteFunction = userIsLoggedIn ? savedPropertyAsFavorite : openModal;

  propertyFaderFavButtons.forEach((buttonEl: HTMLElement) => {
    buttonEl.removeEventListener('click', favoriteFunction);
  });
};

export const initPropertyFaderMutationObserver = (targetEl: string) => {
  // Not sure that this is actually the best place to check now.
  const callback = (mutationList: MutationRecord[]) => {
    mutationList.forEach((mutation: MutationRecord) => {
      const { target } = mutation;
      const el = target as HTMLElement;
      // The image fader might change
      const isFaderChange = el.classList.contains('b-imageFader');
      if (!isFaderChange) {
        unmountImageFaders();
        initImageFader();
        unmountPropertyFaderFavorites();
        initPropertyFaderFavorites();
      }
    });
  };

  const targetNode = document.querySelector(targetEl);

  if (targetNode) {
    const config = { childList: true, subtree: true };
    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
  }
};

export default propertyFaderTemplate;
