/*
    Meilisearch Parameters
    https://docs.meilisearch.com/reference/api/search.html#body

    Instant Meili Search documentation
    https://www.npmjs.com/package/@meilisearch/instant-meilisearch

    Instantsearch Docs
    https://www.algolia.com/doc/guides/building-search-ui/what-is-instantsearch/js/

    // Example on triggering search and how to access the aloglia search helper
    https://discourse.algolia.com/t/how-to-trigger-instantsearch-query-by-setting-input-value-dynamically/9017/3
    https://codesandbox.io/s/instantsearchjs-app-9re0o?file=/src/app.js:1036-1046

    Accessing State to update
    https://www.algolia.com/doc/guides/building-search-ui/going-further/access-state-outside-lifecycle/js/

    Updating parameters without using config
    https://community.algolia.com/algoliasearch-helper-js/reference.html#query-parameters

    TO DO:
    - Search Bar
      - Limit options
*/

// #region - Imports
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import instantsearch from 'instantsearch.js';
import {
  clearRefinements,
  configure,
  currentRefinements,
  hitsPerPage,
  menu,
  rangeInput,
  rangeSlider,
  refinementList,
  searchBox,
  stats
} from 'instantsearch.js/es/widgets';
import {
  connectInfiniteHits,
  connectHits
} from 'instantsearch.js/es/connectors';
import { flattenArray } from '../utils/Index';
import {
  agentListing,
  articleCardTemplate,
  CustomPagination,
  CustomSortBy,
  propertyListingFullCard,
  propertyListingHalfCard,
  propertyTableRow,
  IAgentListing,
  IArticleCard,
  IPropertyListing,
  initListingDropdowns,
  initMapUI,
  getStatsTemplate,
  initPropertyFaderMutationObserver,
  propertyListingNoResults
} from './listingSearch';
// import { arrowLeftIcon, arrowRightIcon } from './templates';
import { initSearchDropdowns } from './SearchBarDropdown';
import listingTypeRefinementList from './listingSearch/ListingTypeRefinementList';
import initTableCollapseItem from '../utils/TableCollapse';
// #endregion - Imports

/* // #region - Types
type TPropertyTypes =
  | 'timberland'
  | 'recreation'
  | 'development'
  | 'agriculture-farmland'
  | 'acreage-estates'
  | 'conservation';


type TPropertyStatuses = 'sold';
*/

interface IWidgetOptions {
  type: string;
  label: string;
  facet: string;
  placeholder: string;
  options?: string[];
  display: string;
  inputTypes: string[];
  min?: number;
  max?: number;
  showFilter?: boolean;
}

type TNamedWidgetsObject = {
  [key: string]: IWidgetOptions;
};

interface IFilters {
  filter?: string;
  facetFilters?: string | string[];
}

interface IGroupedDropdowns {
  [key: string]: string[];
}

interface ISortOption {
  isDefault?: boolean;
  label: string;
  value: string;
}

type THitsTemplateFunctionName =
  | 'propertyListingHalfCard'
  | 'propertyListingFullCard'
  | 'agentListing';

interface IInstantsearchOptions {
  agentName: string;
  index: string;
  inputs: IWidgetOptions[];
  facets: IWidgetOptions[];
  filter: string;
  sortOptions: ISortOption[];
  mapToggle?: boolean;
  showMap?: boolean;
  multiTractName: string[];
  configuration?: {
    hitsPerPage: number;
  };
  useSortBy?: boolean;
  useStats?: boolean;
  useHitsPerPage?: boolean;
  useCurrentRefinements: boolean;
  defaultHitsTemplate: THitsTemplateFunctionName;
  secondaryHitsTemplate: THitsTemplateFunctionName;
  omitPagination: boolean;
  useMap: boolean;
  statsTemplate: string;
  office: string;
}
// #endregion - Types

// variables
const isTable = !!document.querySelector('[data-instantsearchtable-rules]');

// #region - Widget functions
const resetButton = (container: string, facets: string[]) => {
  const containerName =
    Array.from(container)[0] === '#'
      ? `${container}_resetBtn`
      : `#${container}_resetBtn`;

  return clearRefinements({
    container: containerName,
    includedAttributes: [...facets],
    templates: {
      resetLabel: 'Reset'
    },
    cssClasses: {
      button: [
        'b-btn',
        ' b-btn--ghost u-tt-upper u-t-color-core-primary-500',
        ' c-box'
      ]
    }
  });
};

// TO DO: Update type for settings for correct type matching, should be able to import from instant search
const styledRefinementList = (
  container: string,
  item: IWidgetOptions,
  settings?: any
) => {
  const containerEl = document.querySelector(container);
  let widgets: any = [];

  if (containerEl) {
    widgets = [
      refinementList({
        container: containerEl,
        attribute: item.facet,
        limit: 99,
        ...settings
      })
    ];

    if (item.display === 'default' && item.type === 'multipleSelect') {
      const resetBtn = resetButton(container, [item.facet]);
      widgets.push(resetBtn);
    }
  }

  return widgets;
};

// This function could be improved by switch over the switch statement to look for an input or dropdown type.
const getFacetUIByType = (item: IWidgetOptions, categoryTitle?: string) => {
  const container = `#${item.facet}`;

  switch (item.facet) {
    case 'propertyStatus': {
      return menu({
        container,
        attribute: item.facet,
        cssClasses: {
          label: 'u-text-0'
        },
        templates: {
          item(data: any) {
            const { label, url, cssClasses } = data;
            const exception = label.replace(/\s/g, '').toLowerCase();

            return /* html */ `
                <a class="${cssClasses.link}" href="${url}">
                  <span
                    class="b-propertyStatus b-propertyStatus--${exception} ${cssClasses.label} "
                    >${label}</span
                  >
                </a>
              `;
          }
        }
      });
    }
    case 'listingPrice':
    case 'price':
    case 'totalAcres': {
      const rangeSliderUI = rangeSlider({
        container: `${container}_${item.inputTypes[0]}`,
        attribute: item.facet,
        pips: false,
        min: item.min
        // max: item.facet === 'totalAcres' ? 10000 : item.max
      });
      const rangeInputUI = rangeInput({
        container: `${container}_${item.inputTypes[1]}`,
        attribute: item.facet,
        min: item.min,
        max: item.max,
        cssClasses: {
          form: 'c-cluster',
          input: 'c-box',
          submit: 'c-box'
        }
      });

      // Setup button
      const resetRange = resetButton(container, [item.facet]);

      // ~Hacky way to reset the button on initial load for Properties|Auctions listings page
      setTimeout(() => {
        const rangeResetBtn = document.querySelector(
          `${container}_resetBtn button`
        ) as HTMLButtonElement;
        if (rangeResetBtn) {
          rangeResetBtn.click();
        }
      }, 1000);

      // Return widgets
      return [rangeSliderUI, rangeInputUI, resetRange];
    }
    case 'listingTypes': {
      return listingTypeRefinementList({
        // @ts-ignore
        container: document.querySelector(container),
        attribute: 'listingTypes'
      });
    }
    case 'landTypes':
    case 'propertyTypes': {
      const settings = {
        cssClasses: {
          list: 'c-autoGrid',
          item: 'u-pad-200 u-box-flex u-flex-align-center | u-t-bg-neutrals-tertiary-400'
        },
        operator: 'or',
        transformItems(items: any) {
          const filteredItems = items.filter(
            (facetItem: any) => categoryTitle !== facetItem.value
          );
          return filteredItems;
        }
      };
      return styledRefinementList(container, item, settings);
    }
    case 'category':
      return item.showFilter ? styledRefinementList(container, item) : null;
    case 'state': {
      return menu({
        container,
        attribute: item.facet,
        limit: 50,
        cssClasses: {
          selectedItem: 'u-t-bg-grayscale-200'
        },
        transformItems(items) {
          items.sort((a, b) => a.label.localeCompare(b.label));
          return items;
        },
        templates: {
          item(data: any) {
            const { label, url, cssClasses } = data;

            return /* html */ `
              <a class="${cssClasses.link}" href="${url}">
                <span
                  class="${cssClasses.label}"
                  >${label}</span
                >
              </a>
            `;
          }
        }
      });
    }
    case 'auctionType':
    case 'agentName': {
      return styledRefinementList(container, item);
    }
    default:
      return null;
  }
};

const getWidgets = (
  options: IInstantsearchOptions,
  filters: IFilters | null,
  facetWidgets: any,
  instantsearchInstance: any
) => {
  const {
    configuration,
    defaultHitsTemplate,
    inputs,
    // mapToggle,
    secondaryHitsTemplate,
    sortOptions,
    statsTemplate,
    useCurrentRefinements,
    useSortBy,
    useStats,
    useHitsPerPage
    // useMap,
    // omitPagination
  } = options;

  let widgets = [];

  if (configuration) {
    const config = filters
      ? {
          ...configuration,
          ...filters
        }
      : {
          ...configuration
        };
    widgets.push(configure(config));
  } else if (filters) {
    const config = {
      ...filters
    };
    widgets.push(configure(config));
  }
  if (useStats) {
    widgets.push(
      stats({
        container: '#stats',
        templates: {
          text(data) {
            return getStatsTemplate(statsTemplate, data);
          }
        }
      })
    );
  }
  if (useHitsPerPage) {
    // this means we are using pagination
    widgets.push(
      hitsPerPage({
        container: '#hits-per-page',
        items: [
          { label: 'Show 10 per page', value: 10 },
          { label: 'Show 15 per page', value: 15, default: true },
          { label: 'Show 20 per page', value: 20 },
          { label: 'Show 30 per page', value: 30 },
          { label: 'Show 50 per page', value: 50 }
        ]
      }),
      CustomPagination({})
    );
  }
  if (inputs) {
    initSearchDropdowns({
      target: '.b-searchDropdown--instantsearch',
      instantsearchInstance
    });
    widgets.push(
      searchBox({
        container: '#listingMainSearchBar',
        placeholder: inputs[0].placeholder
      })
    );
  }
  if (useCurrentRefinements) {
    // Get the default settings for range inputs and assign them to an object
    // Where the key name is the facets name
    const { facets } = options;
    const rangeFacetSettings = facets.filter((facetObj) => {
      const { type } = facetObj;
      return type === 'range';
    });

    const rangeInputs: TNamedWidgetsObject = rangeFacetSettings.reduce(
      (a, rangeFacet) => ({ ...a, [rangeFacet.facet]: rangeFacet }),
      {}
    );

    widgets.push(
      currentRefinements({
        container: '#refinements',
        cssClasses: {
          list: 'c-cluster',
          item: 'c-cluster',
          category: 'c-cluster'
        },
        transformItems(items) {
          // Check range input types for default values
          const filteredItems = items.filter((item) => {
            const { attribute } = item;

            if (
              attribute === 'price' ||
              attribute === 'listingPrice' ||
              attribute === 'totalAcres'
            ) {
              const { refinements } = item;
              const filteredRanges = refinements.filter((refinement) => {
                const { value } = refinement;
                const defaultSettings: IWidgetOptions = rangeInputs[attribute];
                const { min, max } = defaultSettings;
                // Check value against default
                if (value === min || value === max) {
                  return false;
                }

                return true;
              });

              item.refinements = filteredRanges;
              return filteredRanges.length > 0;
            }

            return true;
          });

          filteredItems.map((item) => {
            const { attribute, label } = item;
            // Add spaces to Label
            const updatedLabel = label.replace(/([a-z])([A-Z])/g, '$1 $2');
            item.label = updatedLabel;

            // Add $ to price refinements
            if (attribute === 'price' || attribute === 'listingPrice') {
              const { refinements } = item;
              refinements.map((refinement) => {
                const pieces = refinement.label.split(' ');
                refinement.label = `${pieces[0]} $${pieces[1]}`;
                return refinement;
              });
              item.refinements = refinements;
            }
            return item;
          });
          return filteredItems;
        }
      })
    );
  }
  if (defaultHitsTemplate) {
    let initialRenderArgs: { isLastPage: boolean };

    if (!isTable) {
      // if is not a table use infinite hits
      const infiniteHitsWidget = connectInfiniteHits(
        (renderArgs: any, isFirstRender: boolean) => {
          const { hits: infiniteHits, showMore, widgetParams } = renderArgs;
          const { container, templateFunctionName } = widgetParams;

          initialRenderArgs = renderArgs;

          if (isFirstRender) {
            // Sentinel for IntersectionObserver
            const sentinel = document.createElement('div');
            const listEl = document.createElement('ul');
            // ListEl for holding hits

            // Apply autoGrid to agentListing container
            if (
              templateFunctionName === 'agentListing' ||
              templateFunctionName === 'articleCardTemplate'
            ) {
              listEl.classList.add('c-autoGrid');
              listEl.classList.add('u-gutter-600');
            } else {
              // Otherwise just add flow
              listEl.classList.add('c-flow');
              listEl.classList.add('u-flow-space--600');
            }

            if (container) {
              container.appendChild(listEl);
              container.appendChild(sentinel);
            }

            // Implement IO
            const observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting && !initialRenderArgs.isLastPage) {
                  try {
                    setTimeout(() => showMore(), 350);
                  } catch (e) {
                    if (window.location.host.includes('dev')) {
                      // eslint-disable-next-line no-console
                      console.error(e);
                    }
                  }
                }
              });
            });

            observer.observe(sentinel);

            return;
          }

          if (container && infiniteHits) {
            container.querySelector('ul').innerHTML = infiniteHits
              .map((hit: any) => {
                switch (templateFunctionName) {
                  case 'propertyListingHalfCard':
                    return propertyListingHalfCard(
                      hit as unknown as IPropertyListing
                    );
                  case 'propertyListingFullCard':
                    return propertyListingFullCard(
                      hit as unknown as IPropertyListing
                    );
                  case 'articleCardTemplate':
                    return articleCardTemplate(hit as unknown as IArticleCard);
                  case 'agentListing':
                    return agentListing(hit as unknown as IAgentListing);
                  default:
                    return '';
                }
              })
              .join('');

            if (infiniteHits.length === 0) {
              container.querySelector('ul').innerHTML =
                propertyListingNoResults();
            }
          }
        }
      );

      widgets.push(
        infiniteHitsWidget({
          // @ts-ignore
          container: document.getElementById('listings'),
          templateFunctionName: defaultHitsTemplate
        })
      );
    } else {
      const renderHits = (renderOptions: any) => {
        const { hits, widgetParams } = renderOptions;
        const { container } = widgetParams;

        if (container) {
          widgetParams.container.innerHTML = hits
            .map((hit: any) => {
              return propertyTableRow(
                hit as unknown as IPropertyListing,
                hit.__position
              );
            })
            .join('');
        }
      };

      const customHits = connectHits(renderHits);
      widgets.push(
        customHits({
          // @ts-ignore
          container: document.getElementById('hits')
        })
      );
    }
  }
  if (secondaryHitsTemplate) {
    // TODO: Needed? if so, adjust with new infiniteHitsWidget changes
    // const hitWidget = hitsWidget(secondaryHitsTemplate, '#listingsFull');
    // widgets.push(hitWidget);
  }
  if (useSortBy) {
    widgets.push(
      CustomSortBy({
        /* @ts-ignore-next-line */
        container: document.getElementById('sortBy'),
        items: sortOptions
      })
    );
  } else {
    const hiddenSortBy = document.getElementById('hiddenSortBy');
    if (hiddenSortBy) {
      widgets.push(
        CustomSortBy({
          /* @ts-ignore-next-line */
          container: hiddenSortBy,
          items: [
            {
              isDefault: true,
              label: 'Last Name (asc)',
              value: 'fncRealEstate_agents:lastName:asc'
            }
          ]
        })
      );
    }
  }
  if (facetWidgets.length > 0) {
    widgets = [...widgets, ...facetWidgets];
  }

  return widgets;
};
// #endregion - Widget functions

const initListingUI = () => {
  // Get form data
  const INSTANT_SEARCH_RULES_EL: HTMLElement = document.querySelector(
    '[data-instantsearch-rules]'
  );
  const INSTANT_SEARCH_TABLE_RULES_EL: HTMLElement = document.querySelector(
    '[data-instantsearchtable-rules]'
  );

  if (INSTANT_SEARCH_RULES_EL || INSTANT_SEARCH_TABLE_RULES_EL) {
    initListingDropdowns();
    const searchClient = instantMeiliSearch(
      process.env.MEILI_FRONTEND_HOST,
      process.env.MEILI_API_KEY,
      {
        keepZeroFacets: true,
        finitePagination: false
      }
    ).searchClient;

    const INSTANT_SEARCH_RULES_DATA = INSTANT_SEARCH_RULES_EL
      ? JSON.parse(INSTANT_SEARCH_RULES_EL.dataset.instantsearchRules)
      : JSON.parse(
          INSTANT_SEARCH_TABLE_RULES_EL.dataset.instantsearchtableRules
        );

    const {
      agentName,
      facets = [],
      filters,
      categoryType,
      category,
      office
    } = INSTANT_SEARCH_RULES_DATA;

    // #region - Category Page default values
    const searchFilters = filters || {};

    if (categoryType && category) {
      searchFilters.filters = `${categoryType} = ${category.title}`;
    }

    // Agent Entry Pages
    if (agentName) {
      searchFilters.facetFilters = `agentName:${agentName}`;
    }

    // Agent Office
    if (office) {
      searchFilters.facetFilters = `office:${office}`;
    }
    // #endregion - Category Page default values

    // #region Grouped dropdowns - Ex: more dropdown in property listing
    const groupedDropdowns: IGroupedDropdowns = {};
    let facetWidgets = facets.map((item: IWidgetOptions) => {
      // In the future we should shift the facets data to match the desired UI
      if (item.display !== 'default') {
        const displayKeyExists = groupedDropdowns[item.display as keyof Object];
        if (!displayKeyExists) {
          // If object doesn't exist
          groupedDropdowns[item.display as keyof Object] = [item.facet];
        } else {
          // If display key exists
          groupedDropdowns[item.display as keyof Object].push(item.facet);
        }
      }
      return getFacetUIByType(item, category ? category.title : 'Title');
    });

    const groupedDropdownKeys = Object.keys(groupedDropdowns);
    groupedDropdownKeys.forEach((key) => {
      const includedAttributes = groupedDropdowns[key];
      facetWidgets.push(resetButton(key, includedAttributes));
    });
    // #endregion - Grouped Dropdowns

    // Remove nulls & flatten widgets array before spreading onto search.addWidgets()
    facetWidgets = facetWidgets.filter(Boolean);
    facetWidgets = flattenArray(facetWidgets);

    let instantsearchInstance: any = null;
    if (INSTANT_SEARCH_RULES_DATA.mapToggle) {
      const mapSearchClient = instantMeiliSearch(
        process.env.MEILI_FRONTEND_HOST,
        process.env.MEILI_API_KEY,
        {
          finitePagination: false
        }
      ).searchClient;
      const mapInstance = instantsearch({
        indexName: INSTANT_SEARCH_RULES_DATA.index,
        searchClient: mapSearchClient
      });
      mapInstance.addWidgets([
        initMapUI(INSTANT_SEARCH_RULES_DATA.mapToggle),
        configure({})
      ]);

      instantsearchInstance = instantsearch({
        indexName: INSTANT_SEARCH_RULES_DATA.index,
        searchClient,
        routing: true,
        searchFunction: (helper) => {
          mapInstance.helper.setState({
            ...helper.state,
            hitsPerPage: 1000,
            page: 0
          });

          mapInstance.helper.search();
          helper.search();
        }
      });

      mapInstance.start();

      if (!INSTANT_SEARCH_RULES_DATA.showMap) {
        const mapEl = document.getElementById('map');
        mapEl.style.visibility = 'hidden';
        const parentEl = mapEl?.parentElement;
        if (parentEl) {
          setTimeout(() => {
            parentEl.classList.add('u-box-hidden');
            mapEl.style.visibility = 'visible';
          }, 100);
        }
      }
    } else {
      instantsearchInstance = instantsearch({
        indexName: INSTANT_SEARCH_RULES_DATA.index,
        searchClient,
        routing: true
      });
    }

    const widgets = getWidgets(
      INSTANT_SEARCH_RULES_DATA,
      searchFilters,
      facetWidgets,
      instantsearchInstance
    );

    instantsearchInstance.addWidgets(widgets);
    // On render could be useful for things like running updates on the
    // image fader. However, it runs everytime the searchbar has input
    // So it doesn't work like an on mount function and I can't find
    // one in the documentation for the vanilla version
    // instantsearchInstance.on('render', () => {});
    instantsearchInstance.start();

    initPropertyFaderMutationObserver('#listings');

    instantsearchInstance.on('render', () => {
      initTableCollapseItem();
    });
  }
};

export default initListingUI;
