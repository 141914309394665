const addDetailRow = (trParent: HTMLElement) => {
  const details = JSON.parse(trParent.dataset.details);
  const { colspan } = trParent.dataset;
  const child = document.createElement('tr');
  child.classList.add('details');

  const td = document.createElement('td');
  td.setAttribute('colspan', colspan);
  td.innerHTML = `
    <div class="b-listingTable__details c-flow u-flow-space-200">
        <div>
            <div class="u-weight-medium">Sold Date:</div>
            <div>${details.soldDate}</div>
        </div>

        <div>
            <div class="u-weight-medium">Status:</div>
            <div><span class="b-propertyStatus b-propertyStatus--${details.status} u-weight-bold">${details.status}</span></div>
        </div>

        <div>
            <div class="u-weight-medium">County:</div>
            <div>${details.county}</div>
        </div>

        <div>
            <div class="u-weight-medium">Listing ID:</div>
            <div class="b-propertyId u-weight-bold"><a href="${details.url}">${details.listingId}</a></div>
        </div>
    </div>
    `;
  child.appendChild(td);
  trParent.after(child);
};

const removeDetailRow = (trParent: HTMLElement) => {
  const sibling = trParent.nextElementSibling;
  if (sibling.classList.contains('details'))
    trParent.nextElementSibling.remove();
};

export const tableCollapseItem = () => {
  const tableEl = document.getElementById('listingTable');
  if (tableEl) {
    const buttons = tableEl.querySelectorAll('button');
    if (buttons.length) {
      buttons.forEach((el) => {
        el.addEventListener('click', (ev) => {
          ev.preventDefault();
          const trParent = <HTMLElement>el.parentNode.parentNode;
          if (!el.classList.contains('b-collapseButton--open')) {
            el.classList.add('b-collapseButton--open');
            addDetailRow(trParent);
          } else {
            el.classList.remove('b-collapseButton--open');
            removeDetailRow(trParent);
          }
        });
      });
    }
  }
};

export default tableCollapseItem;
