export const initModal = (modal: HTMLElement) => {
  if (!modal) {
    return;
  }
  const body: HTMLBodyElement = document.querySelector('body');

  const closeBtn = modal.querySelector('.b-closeBtn');
  const cancelBtn = modal.querySelector('.b-cancelBtn');
  const shadowBox = modal.querySelector('.c-layer--shadowbox');
  const showBtns = document.querySelectorAll('[data-target-modal]');

  const closeModal = () => {
    modal.classList.remove('b-modal--open');
    body.style.overflow = 'auto';
  };

  closeBtn.addEventListener('click', closeModal);

  if (cancelBtn) {
    cancelBtn.addEventListener('click', closeModal);
  }

  if (shadowBox) {
    shadowBox.addEventListener('click', closeModal);
  }

  if (showBtns && showBtns.length > 0) {
    showBtns.forEach((button) => {
      button.addEventListener('click', (e) => {
        const btn = e.target as HTMLElement;
        // This should probably swap out content on a singular modal
        const targetModal = document.querySelector(
          `[data-modal-name='${btn.dataset.targetModal}']`
        );
        targetModal.classList.add('b-modal--open');
        body.style.overflow = 'hidden';
      });
    });
  }
};

export const initModals = () => {
  const modals = document.querySelectorAll('.b-modal');

  if (modals) {
    modals.forEach((modal: HTMLElement) => {
      initModal(modal);
    });
  }
};

export const initSprigModal = () => {
  const sprigModals = document.querySelectorAll('.b-sprigModalObserver');

  if (sprigModals) {
    const callBack = (sprigModalObserver: HTMLElement) => {
      const modal = sprigModalObserver.querySelector('.b-modal') as HTMLElement;
      initModal(modal);
    };

    sprigModals.forEach((sprigModalObserver) => {
      const config = { childList: true, subtree: true };
      const observer = new MutationObserver(() =>
        callBack(sprigModalObserver as HTMLElement)
      );
      observer.observe(sprigModalObserver, config);
    });
  }
};

export default initModals;
