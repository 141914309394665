export const clickDimensions = () => {
  const domain = window.location.hostname ?? 'farmersnational.com';
  const domainString = domain.replace(/\./g, '');
  const cdAnalytics = new clickdimensions.Analytics(
    'analytics.clickdimensions.com'
  );

  cdAnalytics.setAccountKey('akQlxerFwEKVjK2sh8Slg');
  cdAnalytics.setDomain(domain);
  cdAnalytics.setScore(
    typeof cdScore == 'undefined' ? 0 : cdScore == 0 ? null : cdScore
  );

  var optOutHostId = domainString + '_glS8hs2KjVKEwFrexlQka';

  cdAnalytics.optOutTracking(true);
};

export default clickDimensions;
