import { getLoginStatus } from '../utils/LoginStatus';
import { queryParents } from '../utils/QueryParents';

// TO DO add interface for repsonse on favorites

// Also the toggle and get functions are very similar and could probably
// be consolidate using arguments, or an argument not sure what the name would be
async function toggleFavoriteProperty(propertyId: string): Promise<any> {
  const token = document.querySelector(
    '[name="CRAFT_CSRF_TOKEN"]'
  ) as HTMLInputElement;

  const params: RequestInit = {
    headers: {
      'content-type': 'application/json; charset=UTF-8'
    },
    body: JSON.stringify({
      propertyId,
      CRAFT_CSRF_TOKEN: token.value
    }),
    method: 'POST'
  };

  const favoritePropertyResponse = await fetch(
    '/api/v1/properties/favorite',
    params
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.savedProperty) {
        return true;
      }

      return false;
    })
    .catch((err) => {
      if (window.location.href.includes('dev')) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
      return false;
    });

  return favoritePropertyResponse;
}

export async function isPropertyAFavorite(propertyId: string): Promise<any> {
  const userIsLoggedIn = getLoginStatus();
  if (!userIsLoggedIn) return false;

  const token = document.querySelector(
    '[name="CRAFT_CSRF_TOKEN"]'
  ) as HTMLInputElement;

  const params: RequestInit = {
    headers: {
      'content-type': 'application/json; charset=UTF-8'
    },
    body: JSON.stringify({
      propertyId,
      CRAFT_CSRF_TOKEN: token.value
    }),
    method: 'POST'
  };

  const favoritePropertyResponse = await fetch(
    '/api/v1/properties/is-favorite',
    params
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.favorite) {
        return true;
      }

      return false;
    })
    .catch((err) => {
      if (window.location.href.includes('dev')) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
      return false;
    });

  return favoritePropertyResponse;
}

export const openModal = () => {
  const modal = document.querySelector('.b-modal');
  if (modal) {
    modal.classList.add('b-modal--open');
  }
};

export const savedPropertyAsFavorite = (e: Event) => {
  const targetEl = e.target as HTMLButtonElement;
  const button = queryParents(targetEl, 'b-favoritePropertyToggle');
  const { propertyId } = button.dataset;
  const saveListingText: HTMLElement = targetEl.querySelector('span');
  const requestResponse = toggleFavoriteProperty(propertyId);
  if (requestResponse) {
    // Listings
    if (button.classList.contains('b-star')) {
      const activeClass = 'b-favoritePropertyToggle--active';
      if (button.classList.contains(activeClass)) {
        button.classList.remove(activeClass);
      } else {
        button.classList.add(activeClass);
      }
    }
    // Save on detail pages
    if (saveListingText) {
      if (saveListingText.textContent === 'Save Property') {
        saveListingText.textContent = 'Remove from listing';
      } else {
        saveListingText.textContent = 'Save Property';
      }
    }
    // Remove on account favorites
    if (button.classList.contains('b-fav-delete')) {
      const listing = queryParents(button, 'b-profileMain__card');
      listing.remove();
    }
  }
};

// Attach this immediatly not waiting for the promise response
const favoriteButtonPreventPageChanges = (
  buttons: NodeListOf<HTMLButtonElement>
) => {
  buttons.forEach((button: HTMLButtonElement) => {
    button.addEventListener('click', (e) => {
      e.stopPropagation();
      e.preventDefault();
    });
  });
};

// These can be removed and consolidated into the init
const initAnonymousUser = (buttons: NodeListOf<HTMLButtonElement>) => {
  buttons.forEach((button) => {
    button.addEventListener('click', openModal);
  });
};

const initRecognizedUser = (buttons: NodeListOf<HTMLButtonElement>) => {
  buttons.forEach((button) => {
    button.addEventListener('click', savedPropertyAsFavorite);
  });
};

export function initFavoriteProperties(
  targetData = '[data-favorite-property-toggle]'
) {
  const favoriteButtons = document.querySelectorAll(
    targetData
  ) as NodeListOf<HTMLButtonElement>;
  // Check if buttons exist before checking login status
  if (favoriteButtons) {
    favoriteButtonPreventPageChanges(favoriteButtons);
    // Get the login status once, then depending on the login status
    // attach a different event listner to each button with target data.

    // With this method we no longer need to check for different button types
    // There are instances where data-property-id may exist but shouldn't be a favorite button. So instead we can target a different data attr that exists solely as a check of whether the button should function
    // An example I believe is on AFM account page. Where there's an element that does have the data-property-id, but doesn't need to have an on click
    const userIsLoggedIn = getLoginStatus();
    const initButtonsFunction = userIsLoggedIn
      ? initRecognizedUser
      : initAnonymousUser;

    initButtonsFunction(favoriteButtons);
  }
}

export default initFavoriteProperties;
