import Chart from 'chart.js/auto';

// Ready data comming from commodities to be used in chart.js

export const initCharts = () => {
  const rows = document.querySelectorAll('.b-stockListing__row');
  rows.forEach((row, index) => {
    const chartElement = document.getElementById(`stockChart-${index}`);
    const stockData = {
      labels: JSON.parse(chartElement.dataset.labels),
      datasets: [
        {
          data: JSON.parse(chartElement.dataset.prices), // Sample stock prices
          borderColor: chartElement.dataset.color,
          borderWidth: 1,
          pointRadius: 0,
          fill: false
        }
      ]
    };

    // Create a new Chart instance
    const ctx = (chartElement as any).getContext('2d');
    new Chart(ctx, {
      type: 'line',
      data: stockData,
      options: {
        scales: {
          x: {
            display: false // Hide x-axis labels
          },
          y: {
            beginAtZero: false,
            display: false
          }
        },
        plugins: {
          legend: {
            display: false // Hide legend
          }
        }
      }
    });
  });
};

export default initCharts;
