// Sean: Might be a good idea to merge this file with leafletWidget
import { GeoSearchConnectorParams } from 'instantsearch.js/es/connectors/geo-search/connectGeoSearch';
import leafletGeoSearch from './leafletWidget';

const showMap = (
  // halfListings: HTMLElement,
  // fullListings: HTMLElement,
  map: HTMLElement
) => {
  /**
   * Not using half/full cards anymore, always full cards. But leaving in-tact in case that changes
   */
  // halfListings.classList.remove('u-box-hidden');
  // fullListings.classList.add('u-box-hidden');

  // refresh map
  if (window.currentMap) window.currentMap.invalidateSize();

  map.classList.remove('u-box-hidden');

  // for some reason needs to be refreshed again
  if (window.currentMap) window.currentMap.invalidateSize();
};

const hideMap = (
  // halfListings: HTMLElement,
  // fullListings: HTMLElement,
  map: HTMLElement
) => {
  /**
   * Not using half/full cards anymore, always full cards. But leaving in-tact in case that changes
   */
  // halfListings.classList.add('u-box-hidden');
  // fullListings.classList.remove('u-box-hidden');
  map.classList.add('u-box-hidden');
};

const toggleMap = (showMapUI: boolean) => {
  const map: HTMLElement = document.querySelector('.listingMapColumn');
  // const halfListings: HTMLElement = document.querySelector('#listings');
  // const fullListings: HTMLElement = document.querySelector('#listingsFull');

  if (showMapUI) {
    showMap(map);
    // showMap(halfListings, fullListings, map);
  } else {
    hideMap(map);
    // hideMap(halfListings, fullListings, map);
  }
};

async function updateMapToggle(mapIsClosed: string) {
  const token = document.querySelector(
    '[name="CRAFT_CSRF_TOKEN"]'
  ) as HTMLInputElement;

  const params: RequestInit = {
    headers: {
      'content-type': 'application/json; charset=UTF-8'
    },
    body: JSON.stringify({
      showMap: mapIsClosed,
      CRAFT_CSRF_TOKEN: token.value
    }),
    method: 'POST'
  };
  const mapToggleResponse = await fetch(
    '/api/v1/properties/save-map-toggle',
    params
  )
    .then((res) => res.json())
    .then((data) => {
      if (data) {
        return true;
      }
      return false;
    })
    .catch(() => {
      return false;
    });

  return mapToggleResponse;
}

export const initMapUI = (mapToggle: boolean) => {
  const mapToggleButton = document.querySelector('#mapToggleButton');
  if (mapToggleButton) {
    mapToggleButton.addEventListener('click', () => {
      const map = document.querySelector('.listingMapColumn');
      const mapIsClosed = map.classList.contains('u-box-hidden');
      updateMapToggle(mapIsClosed);
      toggleMap(mapIsClosed);
    });

    // const map = document.querySelector('.listingMapColumn');
    // if (map.classList.contains('start-hidden')) {
    //   setTimeout(() => {
    //     toggleMap(false);
    //   }, 100);
    // }
    toggleMap(mapToggle);
  }

  return leafletGeoSearch({
    container: document.querySelector('#map'),
    initialZoom: 15
  } as GeoSearchConnectorParams);
};

export default initMapUI;
