import Swiper from 'swiper';
import {
  A11y,
  Autoplay,
  EffectFade,
  Mousewheel,
  Navigation,
  Pagination
} from 'swiper/modules';

/**
 * Loop through all of our swiper elements and instantiate a new Swiper instance
 * - Passing options etc through data attributes in twig
 */
export default function init() {
  const logCarousels = false;
  const swiperElements = document.querySelectorAll('[data-swiper]');

  if (!swiperElements.length) return;

  swiperElements.forEach((swiperEl: HTMLElement, index: Number) => {
    const swiperUuid = swiperEl.dataset.swiper;
    const swiperSelector = `[data-swiper="${swiperUuid}"]`;
    const swiperOptions = JSON.parse(swiperEl.dataset.swiperOptions);

    const swiper = new Swiper(swiperSelector, {
      modules: [A11y, Autoplay, EffectFade, Mousewheel, Navigation, Pagination],
      keyboard: {
        enabled: true
      },
      navigation: {
        nextEl: `[data-next="${swiperUuid}"]`,
        prevEl: `[data-prev="${swiperUuid}"]`
      },
      slidesPerView: 2.5,
      spaceBetween: 50,
      threshold: 50,
      mousewheel: {
        forceToAxis: true
      },
      watchSlidesProgress: true,
      ...swiperOptions
    });

    window.swipers = window.swipers || [];
    window.swipers.push({
      uuid: swiperUuid,
      swiper
    });

    if (window.location.href.includes('dev') && logCarousels) {
      /* eslint-disable no-console */
      console.log(`Swiper Instance #${index}: `, swiper);
      /* eslint-enable no-console */
    }
  });
}
