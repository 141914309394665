// import { fetchUserLoginStatus } from '../utils/Account';

import { getLoginStatus } from '../utils/LoginStatus';

async function addSearch(searchUrl: string, searchTitle: string): Promise<any> {
  const token = document.querySelector(
    '[name="CRAFT_CSRF_TOKEN"]'
  ) as HTMLInputElement;

  const params: RequestInit = {
    headers: {
      'content-type': 'application/json; charset=UTF-8'
    },
    body: JSON.stringify({
      searchUrl,
      searchTitle,
      CRAFT_CSRF_TOKEN: token.value
    }),
    method: 'POST'
  };
  const favoritePropertyResponse = await fetch(
    '/api/v1/properties/save-search',
    params
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.savedSearch) {
        return true;
      }

      return false;
    })
    .catch((err) => {
      if (window.location.href.includes('dev')) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
      return false;
    });

  return favoritePropertyResponse;
}

const saveUserSearch = () => {
  const keywords = document.getElementById(
    'propertyKeywords'
  ) as HTMLInputElement;
  const requestResponse = addSearch(
    `${window.location.pathname}${window.location.search}`,
    keywords.value ? keywords.value : document.title
  );

  if (requestResponse) {
    // TODO: this should be a modal, or ideally a notification block rather than a window alert.
    // eslint-disable-next-line no-alert
    window.alert('Search successfully saved');
  }
};

const openModal = () => {
  const modal = document.querySelector('.b-modal');
  modal.classList.add('b-modal--open');
};

function handleSaveSearch(button: HTMLElement): void {
  // const userIsLoggedIn = await fetchUserLoginStatus();
  const userIsLoggedIn = getLoginStatus();
  if (userIsLoggedIn) {
    if (button.id === 'saveSearch') {
      const textSpan = button.querySelector('span');
      textSpan.innerHTML = 'Save Search';
    }
    button.addEventListener('click', saveUserSearch);
  } else {
    if (button.id === 'saveSearch') {
      const textSpan = button.querySelector('span');
      textSpan.innerHTML = 'Get Updates';
    }
    button.addEventListener('click', openModal);
  }
}

export default function init() {
  const saveSearch = document.getElementById('saveSearch');
  if (saveSearch) handleSaveSearch(saveSearch);
}
