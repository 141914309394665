import { queryParents } from '../../utils/QueryParents';

// Sean: This viewport logic should be moved into utils eventually
// const isInViewPort = (el: HTMLElement) => {
//   const rect = el.getBoundingClientRect();
//   return (
//     rect.top >= 0 &&
//     rect.left >= 0 &&
//     rect.bottom <=
//       (window.innerHeight || document.documentElement.clientHeight) &&
//     rect.right <= (window.innerWidth || document.documentElement.clientWidth)
//   );
// };

const viewPortCenter = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  return (
    (window.innerWidth || document.documentElement.clientWidth) / 2 - rect.left
  );
};

const isInViewPortRightEdge = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.right <=
    (window.innerWidth || document.documentElement.clientWidth) - 15
  );
};

const isInViewPortLeftEdge = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  return rect.left >= 0;
};

const initListingDropdowns = () => {
  const listingDropdowns = document.querySelectorAll('.b-listingDropdown');

  const closeDropdowns = (wrappingElement?: HTMLElement) => {
    const searchedEl = wrappingElement || document;
    const relevantDropdowns = searchedEl.querySelectorAll('.b-listingDropdown');
    relevantDropdowns.forEach((el) => {
      if (el.classList.contains('open')) {
        el.classList.remove('open');
      }
    });
  };

  if (listingDropdowns) {
    listingDropdowns.forEach((el) => {
      const select = el.querySelector('.b-listingDropdown__select');
      select.addEventListener('click', () => {
        const wrapper = select.parentElement;
        const content: HTMLElement = wrapper.querySelector(
          '.b-listingDropdown__content'
        );
        if (wrapper.classList.contains('open')) {
          wrapper.classList.remove('open');
        } else {
          const parent = queryParents(wrapper, 'b-listingDropdown');
          closeDropdowns(parent);
          wrapper.classList.add('open');

          // #region - Placement Logic
          // This can be moved to it's own function and run on resize as well.
          wrapper.classList.remove('b-listingDropdown--alignRight');
          content.style.removeProperty('left');
          wrapper.classList.remove('b-listingDropdown--alignCenter');

          const withInRightEdge = isInViewPortRightEdge(content);

          if (!withInRightEdge) {
            wrapper.classList.add('b-listingDropdown--alignRight');
            const withInLeftEdge = isInViewPortLeftEdge(content);

            if (!withInLeftEdge) {
              wrapper.classList.remove('b-listingDropdown--alignRight');
              content.style.left = `${viewPortCenter(wrapper)}px`;
              wrapper.classList.add('b-listingDropdown--alignCenter');
            }
          }
          // #endregion - Placement Logic
        }
      });

      const content = el.querySelector('.b-listingDropdown__content');
      content.addEventListener('click', () => {
        closeDropdowns(content as HTMLElement);
      });

      const controls = el.querySelector('.b-listingDropdown__controls');
      if (controls) {
        const doneButton: HTMLElement = el.querySelector(
          '.b-listingDropdown__doneBtn'
        );
        // const resetButton:HTMLElement = el.querySelector('.b-listingDropdown__resetBtn');

        doneButton.addEventListener('click', () => {
          const rangeSubmit: HTMLFormElement = el.querySelector(
            '.ais-RangeInput-submit'
          );
          if (rangeSubmit) {
            rangeSubmit.click();
          }
          el.classList.remove('open');
        });
      }

      el.addEventListener('click', (e) => {
        e.stopPropagation();
      });
    });

    window.addEventListener('click', (e) => {
      const el = e.target as HTMLElement;
      // There's a better way to loop through this here then to have a click on the content but I can't think of the best way right now.
      // const parent = queryParents(el, 'b-listingDropdown');

      const isDropdown =
        el.matches('.b-listingDropdown__select') ||
        el.matches('.b-listingDropdown__content');

      if (!isDropdown) {
        const dropdowns = document.querySelectorAll('.b-listingDropdown');
        dropdowns.forEach((dropdown) => {
          if (dropdown.classList.contains('open')) {
            dropdown.classList.remove('open');
          }
        });
      }
    });

    window.addEventListener('resize', () => {
      closeDropdowns();
    });
  }
};

// eslint-disable-next-line import/prefer-default-export
export { initListingDropdowns };
