import initNavAccountLinks from '../modules/NavAccountLinks';
import { initFavoriteProperties } from '../modules/FavoriteProperties';
import initSavedSearches from '../modules/SavedSearches';

/**
 * Fetch to see if there's a user currently logged in.
 *
 * @return    {boolean}             Returns true if logged in, false if not
 */
export async function fetchUserLoginStatus(): Promise<any> {
  const userLoginStatusResponse = await fetch('/api/v1/login-status')
    .then((res) => res.json())
    .then((data) => {
      if (data.loginStatus && data.loginStatus === true) {
        return data.loginStatus;
      }
      return false;
    })
    .catch((err) => {
      if (window.location.href.includes('dev')) {
        console.error(err); // eslint-disable-line
      }
      return false;
    });

  return userLoginStatusResponse;
}

export const initLoginStatusComponents = () => {
  const { hostname } = window.location;
  const siteHasAccounts =
    hostname.match('realestate') ||
    hostname.match('energy') ||
    hostname.match('hln') ||
    hostname.match('hunting') ||
    hostname.match('stock');

  if (siteHasAccounts) {
    fetchUserLoginStatus().then((value) => {
      sessionStorage.setItem('userLoginStatus', value);
      initNavAccountLinks();
      initFavoriteProperties();
      initSavedSearches();
    });
  }
};

/**
 * Adjust default export if we add more account utilities
 */
export default initLoginStatusComponents;
