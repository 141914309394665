export const initShareCurrentUrl = () => {
  const shareButtons = document.querySelectorAll('.b-shareCurrentUrl');
  shareButtons.forEach((el) => {
    el.addEventListener('click', () => {
      const url = window.location.href;
      navigator.clipboard.writeText(url).then(() => {
        // eslint-disable-next-line no-alert
        alert('Copied to clipbaord');
      });
    });
  });
};

export default initShareCurrentUrl;
