import { getSiteHandle } from '../../utils/GetSiteHandle';
import { spriteIcon } from '../templates';
import { buttonTemplate } from '../templates/Button';

export interface IAgentListing {
  cellPhone: string;
  email: string;
  firstName: string;
  lastName: string;
  officePhone: string;
  photo: string;
  url: string;
  title: string;
}

const phoneLink = (phoneNumber: string, iconName: string) => {
  if (!phoneNumber || phoneNumber === 'N/A') {
    return '';
  }
  const numbers = phoneNumber.split('.').join('');
  return /* html */ `
  <a href='tel:1${numbers}'>
    ${spriteIcon(iconName, 'u-t-color-core-secondary')}
    <span class='u-t-color-grayscale-500'>${phoneNumber}</span>
  </a>
  `;
};

export const agentListing: (data: IAgentListing) => string = ({
  cellPhone,
  email,
  firstName,
  lastName,
  officePhone,
  photo,
  url,
  title
}) => {
  const siteHandle = getSiteHandle();
  const userTitleEl = title ? `<p>${title}</p>` : '';
  const officePhoneEl = phoneLink(officePhone, 'phone');
  const cellPhoneEl = phoneLink(cellPhone, 'cellphone');
  const buttonTheme =
    siteHandle === 'huntingLeaseNetwork' ? 'secondary' : 'primary';
  return /* html */ `
  <div class='b-agentListing | c-cluster'>
    <div class='b-agentListing__image c-frame c-frame--aspect-1-1'>
      <a href='${url}'>
        <img src='${photo}' />
      </a>
    </div>
    <div class='b-agentListing__content | c-flow'>
      <div>
        <div class='c-flow'>
          <h3 class='b-agentListing__name | u-weight-demi'>${firstName} ${lastName}</h3>
          ${userTitleEl}
        </div>
        <div class='b-agentListing__phones c-flow'>
        </div>
      </div>
      <div class='c-repel'>
        ${cellPhoneEl}
        ${officePhoneEl}
      </div>
      <div class='c-repel'>
        ${buttonTemplate({
          tagType: 'a',
          className: `b-agentListing__viewProfile | b-button--${buttonTheme} b-button--with-icon | c-withIcon`,
          href: url,
          content: 'Profile',
          iconName: 'arrow-right'
        })}
        ${buttonTemplate({
          tagType: 'a',
          className: `b-button | b-button--${buttonTheme} b-button--clear b-button--with-icon b-button--clearHover`,
          href: `mailto:${email}`,
          iconName: 'arrow-right',
          content: `Contact`
        })}
      </div>
    </div>
  </div>`;
};

export default agentListing;
