export const leafletAttributionHover = () => {
  const controlAttribution = document.querySelectorAll(
    '.leaflet-control-attribution'
  );

  if (controlAttribution.length) {
    controlAttribution.forEach((controlEl) => {
      controlEl.addEventListener('click', () => {
        controlEl.classList.toggle('u-whitespace-normal');
      });
    });
  }
};

export default leafletAttributionHover;
