/* StatsTemplate
  Documentation
  @see https://www.algolia.com/doc/api-reference/widgets/stats/js/

  Dev Notes (Sean):
  I like the structure of this more than what's going on with the hits templates.
  May be a good idea to pull those out and have
  a highorder function in it's own file
*/

import { getSiteHandle } from '../../utils/GetSiteHandle';

// Templates
export const defaultStatsTemplate = (data: any) => {
  let count = '';

  if (data.hasManyResults) {
    count += `${data.nbHits} results matched your search`;
  } else if (data.hasOneResult) {
    count += '1 result';
  } else {
    count += 'No results found';
  }

  return count;
};

type TGetName = (handle: string, isMultiple?: boolean) => string;
export const getName: TGetName = (handle = '', isMultiple = false) => {
  const s = isMultiple ? 's' : '';
  switch (handle) {
    case 'fncAppraisal':
      return `appraiser${s}`;
    case 'huntingLeaseNetwork':
      return `rep${s}`;
    default:
      return `agent${s}`;
  }
};

export const agentsStatsTemplate = (data: any) => {
  const siteHandle = getSiteHandle();

  if (data.hasManyResults) {
    const color =
      siteHandle === 'huntingLeaseNetwork' ? 'tertiary-500' : 'primary-500';
    return `Showing <span class='u-weight-bold u-t-color-core-${color}'>${
      data.nbHits
    }</span> ${getName(siteHandle, true)} near you`;
  }
  if (data.hasOneResult) {
    return `Found an ${getName(siteHandle, false)} near you`;
  }
  return `Sorry we couldn't find an ${getName(siteHandle, true)} near you.`;
};

export const numShowingTemplate = (data: any) => {
  const { hitsPerPage, nbHits, nbPages, page } = data;
  if (nbHits <= hitsPerPage) {
    return `Showing all ${data.nbHits} stories`;
  }
  const lastPage = page + 1 === nbPages;
  if (lastPage) {
    const numRemaining = nbHits - (nbPages - 1) * hitsPerPage;

    return `Showing (${numRemaining}) of ${nbHits} stories`;
  }
  return `Showing (${hitsPerPage}) of ${nbHits} stories`;
};

export const getStatsTemplate = (templateName: string, data: any) => {
  switch (templateName) {
    case 'agent':
      return agentsStatsTemplate(data);
    case 'numShowing':
      return numShowingTemplate(data);
    default:
      return defaultStatsTemplate(data);
  }
};

export default getStatsTemplate;
