// Degrees / Education
const addDegree = document.getElementById('addDegree');
const degreeContainer = document.getElementById('degreeContainer');

// Broker In Charge
const addBrokerInCharge = document.getElementById('addLicense');
const licenseContainer = document.getElementById('licenseContainer');

// Generate ID for custom fields
function generateID() {
  return (Math.random() + 1).toString(36).substring(7);
}

// Remove Row
function initRemoveButtons(selector: string, parentContainer: HTMLElement) {
  const removeButtons = document.querySelectorAll(selector);

  removeButtons.forEach((removeBtn) => {
    removeBtn.addEventListener('click', () => {
      const container = document.getElementById(
        removeBtn.getAttribute('data-id')
      );
      parentContainer.removeChild(container);
    });
  });
}

export default function init() {
  // Initialize all remove buttons
  initRemoveButtons('.b-profileMain__buttonContainer', degreeContainer);
  initRemoveButtons('.b-profileMain__buttonContainer', licenseContainer);

  // Degree Logic
  if (addDegree) {
    addDegree.addEventListener('click', () => {
      // Generate ID
      const id = generateID();
      // Degrees / Education
      const inputContainer = document.createElement('div');

      inputContainer.id = `inputContainer_${id}`;
      inputContainer.className = 'u-gap-top-400';
      inputContainer.innerHTML = `<fieldset class="c-flow c-withLayer">
        <label class="b-form__inputLabel" for="degree_${id}">Degree</label>
        <div class="b-input b-inputText c-withLayer">
          <input type="text" id="degree_${id}" class="b-form__inputText c-box | b-form__inputText c-box" name="degree[${id}][name]" placeholder="Degree Title">
          <div class="c-layer c-cluster c-box | u-flex-justify-end">
            <svg class="b-icon b-input__complete u-t-color-state-good"><use href="/svg/sprite.svg#checkmark"></use></svg>
            <svg class="b-icon b-input__incomplete u-t-color-state-bad"><use href="/svg/sprite.svg#x"></use></svg>
            <button class="b-input__control" type="button"><svg class="b-icon b-resetInput u-t-color-grayscale-900"><use href="/svg/sprite.svg#circle-x"></use></svg></button>
          </div>
        </div>
        <p class="b-inputHint u-text-00"></p></fieldset>
        <div class="c-cluster | u-gap-top-400"><fieldset class="c-flow c-withLayer">
          <label class="b-form__inputLabel" for="degreeYear_${id}">Degree Year</label>
        <div class="b-input b-inputText c-withLayer">
          <input type="text" id="degreeYear_${id}" class="b-form__inputText c-box | b-form__inputText c-box" name="degree[${id}][year]" placeholder="Degree Year">
          <div class="c-layer c-cluster c-box | u-flex-justify-end">
            <svg class="b-icon b-input__complete u-t-color-state-good"><use href="/svg/sprite.svg#checkmark"></use></svg>
            <svg class="b-icon b-input__incomplete u-t-color-state-bad"><use href="/svg/sprite.svg#x"></use></svg>
            <button class="b-input__control" type="button"><svg class="b-icon b-resetInput u-t-color-grayscale-900"><use href="/svg/sprite.svg#circle-x"></use></svg></button>
          </div>
        </div>
        <p class="b-inputHint u-text-00"></p></fieldset>
        <div class="b-profileMain__buttonContainer u-box-flex u-flex-dir-row u-flex-self-end"><button class="remove-degree | u-gap-right-200" type="button" data-id="inputContainer_${id}">
          <svg class="b-icon "><use href="/svg/sprite.svg#trash"></use></svg></button><span class="u-tt-upper u-weight-bold">Delete</span></div></div>
        </div>`;

      degreeContainer.appendChild(inputContainer);
      initRemoveButtons('.b-profileMain__buttonContainer', degreeContainer);
    });
  }

  // Licenses Logic
  if (addBrokerInCharge) {
    addBrokerInCharge.addEventListener('click', () => {
      // Generate ID
      const id = generateID();
      // Broker In Charge
      const inputBrokerContainer = document.createElement('div');
      const states = JSON.parse(
        (<HTMLInputElement>document.getElementById('states')).value
      );
      const users = JSON.parse(
        (<HTMLInputElement>document.getElementById('users')).value
      );
      // Dropdown
      let statesDropdown = `<div class="c-withLayer"><label class="b-form__inputLabel" for="state_${id}">State</label>
        <div class="b-profileMain__dropdown b-select | c-withLayer"><select id="state_${id}" class="b-input | c-box" name="licenses[${id}][state]">`;
      let usersDropdown = `<div class="c-withLayer"><label class="b-form__inputLabel" for="brokerInCharge_${id}">Broker in Charge</label><div class="b-profileMain__dropdown b-select | c-withLayer"><select id="brokerInCharge_${id}" class="b-input | c-box" name="licenses[${id}][brokerInCharge]">`;

      states.forEach((state: any) => {
        statesDropdown += `<option value="${state.id}">${state.title}</option>`;
      });

      users.forEach((user: any) => {
        usersDropdown += `<option value="${user.id}">${user.title}</option>`;
      });

      statesDropdown +=
        '</select><div class="c-layer c-box | u-box-flex u-flex-justify-end u-flex-align-center"><svg class="b-icon "><use href="/svg/sprite.svg#caret-down"></use></svg></div></div></div>';
      usersDropdown +=
        '</select><div class="c-layer c-box | u-box-flex u-flex-justify-end u-flex-align-center"><svg class="b-icon "><use href="/svg/sprite.svg#caret-down"></use></svg></div></div></div>';

      inputBrokerContainer.id = `inputLicenseContainer_${id}`;
      inputBrokerContainer.className = 'u-gap-top-400';
      inputBrokerContainer.innerHTML = `<fieldset class="c-flow c-withLayer"><label class="b-form__inputLabel" for="licenseNumber_${id}">License Number</label>
      <div class="b-input b-inputText c-withLayer"><input type="text" id="licenseNumber_${id}" class="b-form__inputText c-box | b-form__inputText c-box" name="licenses[${id}][licenseNumber]" autocomplete="username" placeholder="License Number">
        <div class="c-layer c-cluster c-box | u-flex-justify-end">
          <svg class="b-icon b-input__complete u-t-color-state-good"><use href="/svg/sprite.svg#checkmark"></use></svg>
          <svg class="b-icon b-input__incomplete u-t-color-state-bad"><use href="/svg/sprite.svg#x"></use></svg>
          <button class="b-input__control" type="button"><svg class="b-icon b-resetInput u-t-color-grayscale-900"><use href="/svg/sprite.svg#circle-x"></use></svg></button>
        </div>
      </div>
      <p class="b-inputHint u-text-00"></p></fieldset>
      <div class="c-cluster | u-gap-top-400">
        ${statesDropdown}${usersDropdown}
      <div class="b-profileMain__buttonContainer u-box-flex u-flex-dir-row u-flex-self-end" data-id="inputLicenseContainer_${id}"><button class="u-gap-right-200" type="button"><svg class="b-icon "><use href="/svg/sprite.svg#trash"></use></svg></button><span class="u-tt-upper u-weight-bold">Delete</span></div></div>`;

      licenseContainer.appendChild(inputBrokerContainer);
      initRemoveButtons('.b-profileMain__buttonContainer', licenseContainer);
    });
  }

  // Credentials Logic
  const credentialsBoxes = document.querySelectorAll(
    '.b-profileMain__checkbox'
  );

  credentialsBoxes.forEach((box: HTMLInputElement) => {
    box.addEventListener('click', () => {
      const credentialContainer = box.parentNode.parentNode as HTMLElement;
      if (box.checked) {
        credentialContainer.classList.remove('b-button--tertiary');
        credentialContainer.classList.add('b-button--primary');
      } else {
        credentialContainer.classList.remove('b-button--primary');
        credentialContainer.classList.add('b-button--tertiary');
      }
    });
  });
}
