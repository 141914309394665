import { toggleResetBtn } from './Input';
import { getCoordinatesZipCode } from '../utils/ZipCodeLookup';
import { onlySpaces } from '../utils/Index';
import { queryParents } from '../utils/QueryParents';

interface SearchInit {
  target: string;
  instantsearchInstance?: any;
}

// #region Current Location Logic
export const currentLocationSuccess = async (
  pos: GeolocationPosition,
  searchBar: HTMLElement,
  search?: any
) => {
  const { coords } = pos;
  const { latitude, longitude } = coords;
  const zipcode = await getCoordinatesZipCode(latitude, longitude);
  const searchBarInput = searchBar.querySelector('input');
  const inputValue = searchBarInput.value;
  const isInputEmpty = onlySpaces(inputValue);
  if (isInputEmpty || !search) {
    searchBarInput.value = `${zipcode}`;
  } else {
    searchBarInput.value += `, ${zipcode}`;
  }

  if (search) {
    search.helper.setQuery(searchBarInput.value).search();
  }
  // searchBarInput.focus();
};

export const currentLocationFailure = async (err: GeolocationPositionError) => {
  // eslint-disable-next-line no-console
  console.log(err);
  // eslint-disable-next-line no-alert
  alert('Please enable location services to use your current location.');
};
// #endregion Current Location Logic

export const initSearchTypeBtns = (searchBar: HTMLElement) => {
  const searchByTypeBtnClass = 'b-searchByType';
  const activeClass = `${searchByTypeBtnClass}--active`;
  const searchByTypeButtons = searchBar.querySelectorAll(
    `.${searchByTypeBtnClass}`
  );
  if (searchByTypeButtons) {
    searchByTypeButtons.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        // Get Button
        const { target } = e;
        const el = target as HTMLElement;
        const searchTypeButton = el.classList.contains(searchByTypeBtnClass)
          ? (target as HTMLButtonElement)
          : (queryParents(el, searchByTypeBtnClass) as HTMLButtonElement);
        const {
          dataset: { searchtype }
        } = searchTypeButton;

        // Reset active button
        const currentActiveBtn = searchBar.querySelector(`.${activeClass}`);
        currentActiveBtn.classList.remove(activeClass);

        searchTypeButton.classList.add(activeClass);
        searchBar.dataset.activeSearchtype = searchtype;
      });
    });
  }
};

export const initSearchDropdown = (searchBar: HTMLElement, search?: any) => {
  initSearchTypeBtns(searchBar);

  const currentLocation = searchBar.querySelector('.b-currentLocation');
  currentLocation.addEventListener('click', () => {
    searchBar.classList.remove('open');
    const loadingText: HTMLElement = searchBar.querySelector('.b-loadingText');
    loadingText.style.display = 'block';
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        currentLocationSuccess(pos, searchBar, search).then(() => {
          const searchBarInput = searchBar.querySelector('input');
          if (!search) {
            searchBarInput.dispatchEvent(new Event('change'));
          }
          // searchBarInput.focus();
          loadingText.style.removeProperty('display');
        });
      },
      (err) => {
        currentLocationFailure(err).then(() => {
          const searchBarInput = searchBar.querySelector('input');
          searchBarInput.focus();
          loadingText.style.removeProperty('display');
        });
      },
      {
        timeout: 10000 // To prevent the function from sliently failing, time can be adjusted
      }
    );
  });

  const searchInput: HTMLInputElement = searchBar.querySelector(
    '.ais-SearchBox-input'
  );

  if (searchInput) {
    const resetBtn: HTMLElement = searchBar.querySelector(
      '.ais-SearchBox-reset'
    );
    searchInput.placeholder = 'Search';

    searchInput.addEventListener('change', () => {
      const { value } = searchInput;
      const hasInput = value === '';
      toggleResetBtn(resetBtn, hasInput);
    });

    searchInput.addEventListener('beforeinput', (e) => {
      const { inputType } = e;
      const charCount = searchInput.value ? searchInput.value.length : null;
      // Use type such as deleteContentBackward
      if (e.data !== null) {
        toggleResetBtn(resetBtn, false);
      } else if (searchInput.value !== '') {
        if (charCount === 1 && inputType === 'deleteContentBackward') {
          toggleResetBtn(resetBtn, true);
        } else {
          toggleResetBtn(resetBtn, false);
        }
      } else {
        toggleResetBtn(resetBtn, true);
      }
    });

    resetBtn.addEventListener('click', () => {
      resetBtn.style.removeProperty('display');
    });
  }
};

// Searchbar Mutation Oberver - used as on mount
export const initSearchBarMutationObserver = (
  searchBar: HTMLElement,
  search: any
) => {
  if (searchBar) {
    const unmountMutationObserver = (observer: MutationObserver) => {
      observer.disconnect();
    };

    const callback = (mutationList: MutationRecord[]) => {
      if (mutationList.length > 0) {
        initSearchDropdown(searchBar, search);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        unmountMutationObserver(observer);
      }
    };

    const config = { childList: true, subtree: true };
    const observer = new MutationObserver(callback);
    observer.observe(searchBar, config);
  }
};

export const initSearchDropdowns = ({
  target,
  instantsearchIntance
}: SearchInit) => {
  const searchBars = document.querySelectorAll(target);

  if (searchBars) {
    searchBars.forEach((searchBar) => {
      // initSearchDropdown(searchBar as HTMLElement, search);
      initSearchBarMutationObserver(
        searchBar as HTMLElement,
        instantsearchIntance
      );
    });
  }
};

export const initZipSearch = () => {
  const target = '.b-searchDropdown--zip';
  initSearchDropdowns({ target });
};

export const initHeroSearch = () => {
  const heroSearchBar = document.querySelector('#heroSearchBarWrapper');
  if (heroSearchBar) {
    const callback = () => {
      const getQuery = () => {
        const url = window.location.href;
        const urlPieces = url.split(/[/]/);
        return urlPieces[urlPieces.length - 1];
      };

      const form = heroSearchBar.querySelector('form');

      form.addEventListener('submit', () => {
        const currentQuery = getQuery();
        const subSite = window.location.pathname.split('/')[1];
        window.location.href = `/${subSite}/properties/${currentQuery}`;
      });

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      observer.disconnect();
    };

    const config = { childList: true, subtree: true };
    const observer = new MutationObserver(callback);
    observer.observe(heroSearchBar, config);
  }
};

export default initSearchDropdown;
