import { spriteIcon } from '../templates';

export interface IArticleCard {
  image: string;
  publishedDate: string;
  teaserText: string;
  title: string;
  url: string;
  category: string;
}

export const articleCardTemplate: (data: IArticleCard) => string = ({
  image,
  publishedDate,
  teaserText,
  title,
  url,
  category
}) => {
  return /* html */ `
    <a href='${url}' class='b-anchorComplex'>
      <article class='b-articleCard | c-flow'>
        <div class='c-frame c-frame--aspect-4-3 c-withLayer'>
          <img src='${image}' />
          <div class='c-layer'>
            <div class='hoverBar hoverBar--top'></div>
          </div>
        </div>
        <div class='c-repel'>
          <p class='u-t-color-core-primary-500 u-tt-upper u-weight-extra-bold u-text-0'>
            ${category}
          </p>
          <p class='u-t-color-grayscale-500 u-tt-upper u-weight-extra-bold u-text-0'>
            <time>${publishedDate}</time>
          </p>
        </div>
        <h3 class='headline | u-t-color-core-primary u-text-2 u-weight-extra-bold'>${title}</h3>
        <div class='u-t-color-core-tertiary'>${teaserText}</div>
        <div class='u-gap-top-300 u-gap-bottom-400 sm:u-gap-bottom-300 md:u-gap-top-500 md:u-gap-bottom-0'>
          <button class='b-button b-anchorComplex b-button--clear b-button--primary b-button--with-icon | c-withIcon b-button--clearHover u-pad-block-0'>
            <span class='b-button__text | u-tt-upper | u-pad-right-300'>Read More</span>
            ${spriteIcon('arrow-right')}
          </button>
        </div>
      </article>
    </a>
  `;
};

export default articleCardTemplate;
