const toggleButtons = document.getElementsByClassName('collapse-button');

function slideEl(container: HTMLElement) {
  if (!container.classList.contains('b-faq__collapse__item__body--open')) {
    container.classList.add('b-faq__collapse__item__body--open');
    container.style.height = 'auto';
    const height = `${container.clientHeight}px`;
    container.style.height = '0px';

    setTimeout(() => {
      container.style.height = height;
    }, 0);
  } else {
    container.style.height = '0px';
    container.addEventListener(
      'transitionend',
      () => {
        container.classList.remove('b-faq__collapse__item__body--open');
      },
      { once: true }
    );
  }
}

export default function init() {
  if (toggleButtons.length) {
    Array.from(toggleButtons).forEach((el) => {
      el.addEventListener('click', () => {
        const contentId = el.getAttribute('aria-controls');
        const contentEl = document.getElementById(contentId);
        const isExpanded = el.getAttribute('aria-expanded') === 'true';

        el.classList.toggle('b-faq__collapse__item__button--active');

        if (!isExpanded) {
          contentEl.setAttribute('aria-hidden', 'false');
          el.setAttribute('aria-expanded', 'true');
          slideEl(contentEl);
        } else {
          contentEl.setAttribute('aria-hidden', 'true');
          el.setAttribute('aria-expanded', 'false');
          slideEl(contentEl);
        }
      });
    });
  }
}
